import { useAuth0 } from "@auth0/auth0-react";

const ROLES_ID_TOKEN = 'https://stethoscope.globuleadm.net/roles' as const;

export function useTestForRoles(): (allOf?: string[], anyOf?: string[]) => boolean;
export function useTestForRoles(allOf?: string[], anyOf?: string[]): boolean;

export function useTestForRoles(allOf?: string[], anyOf?: string[]): any {
  const { isAuthenticated, user } = useAuth0();
  let roles: string[];

  let r: (_allOf?: string[], _anyOf?: string[]) => boolean = () => false;
  if (isAuthenticated && user && Array.isArray(roles = user[ROLES_ID_TOKEN])) {
    if (allOf?.length === 1) return roles.includes(allOf[0]);
    r = (_allOf, _anyOf) => (!!_allOf && _allOf.every(n => roles.includes(n))) || (!!_anyOf && _anyOf.some(n => roles.includes(n)));
  }

  return allOf || anyOf ? r(allOf, anyOf) : r;
}
