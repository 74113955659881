import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'primereact/button';

export default function LogoutButton() {
  const { logout } = useAuth0();
  return (
    <Button
      className="p-button-warning p-m-3"
      label="Déconnexion"
      onClick={() => logout({ returnTo: window.location.origin })} />
  );
}
