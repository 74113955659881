import { useAuth0 } from '@auth0/auth0-react';

import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';
import { SpinnerOnly } from '../Loading';


export default function AuthenticationButton() {
  const { isAuthenticated, isLoading } = useAuth0();

  return (
    <SpinnerOnly loading={isLoading}>
      {isAuthenticated ? <LogoutButton /> : <LoginButton />}
    </SpinnerOnly>
  );
}
