import React from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter as HistoryProvider, useHistory } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Auth0Provider } from '@auth0/auth0-react';
import store from './store';

export default function AppWrapper(state: { children: React.ReactNode }) {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN!;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID!;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE!;

  const OAuthProvider = (state: { children: React.ReactNode }) => {
    const history = useHistory();

    return <Auth0Provider domain={domain} clientId={clientId} audience={audience}
        onRedirectCallback={appState => history.push(appState?.returnTo || window.location.pathname)}
        redirectUri={window.location.origin}>{state.children}</Auth0Provider>;
  };

  return (
    <React.StrictMode>
      <StoreProvider store={store}>
        <HistoryProvider>
          <OAuthProvider>
            <HelmetProvider>
              {state.children}
            </HelmetProvider>
          </OAuthProvider>
        </HistoryProvider>
      </StoreProvider>
    </React.StrictMode>
  );
}
