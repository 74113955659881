import { CSSProperties, FormEvent, useRef, useState } from 'react';
import { useAPICaller, useAPISelect } from '../../../helpers/useAPI';
import { callServerMeta } from '../../../app/commonSlice';
import { useOnClickAway } from '../../../helpers/useOnClickAway';
import { useTestForRoles } from '../../../helpers/useTestForRoles';

import './MuleApps.module.css';
import { SpinnerText } from '../../Loading';
import { RoleNeeded } from '../../authentication/RoleNeeded';
import { OrderList } from 'primereact/orderlist';
import { Inplace, InplaceContent, InplaceDisplay } from 'primereact/inplace';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { OverlayPanel } from 'primereact/overlaypanel';

type EditableProps = {
  editable?: boolean,
  /** value to display at render (not "an initial value that would be modified", the editing logic should be in onUpdate) */
  value?: string|number,
  /** called when value is edited, if it returns a boolean, its value is used to set valid/invalid state (red border) */
  onUpdate: (niw: string) => boolean|void|undefined,
  className?: string,
  style?: CSSProperties
};

/**
 * a field (like 'region' or 'url') that can be edited
 * 
 * layout:
 * - an Inplace that toggle between
 *   - simple div `props.value || "Non défini(e)"`
 *   - InputText
 * 
 * becomes active when user clicks in ('focus' event)
 * and back to inactive (initial state) when clicks away ('blur' event)
 */
function Editable(props: EditableProps) {
  const [isActive, setActive] = useState(false);
  const innerRef = useOnClickAway<HTMLDivElement>(() => isActive && setActive(false));

  const [isValid, setValid] = useState(true);
  const onChange = (e: FormEvent<HTMLInputElement>) => {
    const vld = props.onUpdate(e.currentTarget.value);
    if (vld === !!vld) setValid(vld);
  };

  return (
    <div className={props.className} style={props.style} ref={innerRef}>
      <Inplace active={isActive} onToggle={e => props.editable && setActive(e.value)} className="p-inplace-display-revert-style" disabled={!props.editable}>
        <InplaceDisplay>
          <div style={{ border: '1px solid #0000' }}>{props.value || "Non défini(e)"}</div>
        </InplaceDisplay>
        <InplaceContent>
          <InputText value={props.value} onChange={onChange} autoFocus className={isValid ? "" : "p-invalid"} style={{ width: '100%' }} />
        </InplaceContent>
      </Inplace>
    </div>
  );
}

type MuleAppType = api_v1.MuleAppInfo extends { [name: string]: infer AppType } ? AppType : never;
type MuleAppEntryProps = {
  /** initial value only, updated value is a component state and only accessible in onUpdate and onRemove */
  name: string,
  /** initial value only, updated value is a component state and only accessible in onUpdate */
  latest?: number,
  /** initial value only, updated value is a component state and only accessible in onUpdate */
  url: string,
  /** initial value only, updated value is a component state and only accessible in onUpdate */
  region: string,
  editable?: boolean,
  /** function to call when the user want to update the app (clicking the "Sauvegarder" button) */
  onUpdate?: (name: string, info: MuleAppType) => void,
  /** function to call when the user want to remove the app (clicking the "Supprimer" button) */
  onDelete?: (name: string) => void
};

/**
 * represent one line of the list of apps
 * 
 * layout:
 * - name (Editable)
 * - latest (Editable)
 * - url (Editable)
 * - region (Editable)
 * - button "Sauvegarder" if app's info have been changed, "Supprimer" otherwise
 */
function MuleAppEntry(props: MuleAppEntryProps) {
  const [name, setName] = useState(props.name);
  const [latest, setLatest] = useState(props.latest ?? 0);
  const [url, setUrl] = useState(props.url);
  const [region, setRegion] = useState(props.region);

  const edited = props.name !== name || props.latest !== latest || props.url !== url || props.region !== region;

  return (
    <div className="p-d-flex" style={{ width: '100%' }}>
      <Editable value={name} onUpdate={setName} editable={props.editable}
        className={"editable-muleapp-name" + (props.name !== name ? " editable-edited" : "")} />
      <Editable value={latest} onUpdate={n => !isNaN(+n) && setLatest(+n)}
        editable={props.editable} className={"editable-muleapp-latest" + (props.latest !== latest ? " editable-edited" : "")} />
      <Editable value={url} onUpdate={setUrl} editable={props.editable}
        className={"editable-muleapp-url" + (props.url !== url ? " editable-edited" : "")} />
      <Editable value={region} onUpdate={setRegion} editable={props.editable}
        className={"editable-muleapp-region" + (props.region !== region ? " editable-edited" : "")} />

      {props.editable && <Button icon={edited ? "pi pi-cloud-upload" : "pi pi-trash"} label={edited ? "Sauvegarder" : "Supprimer"}
        onClick={() => (edited ? props.onUpdate : props.onDelete)?.(name, { latest, url, region })}
        className={"editable-muleapp-button p-button-outlined p-button-" + (edited ? "info" : "warning")} />}
    </div>
  );
}

/**
 * adds the button in the bottom corner and a toggleable panel for adding an app
 * 
 * layout:
 * - a form with
 *   - first line: both name and version (ie latest)
 *   - second line: the url only
 *   - third line: region (default value pre-set to "eu-central-1") and the confirm button
 * 
 * each field is validated using a custom check
 * name: invalid if empty or contains whitespace character (/\s/)
 * latest: never invalid
 * url: invalid if empty or not like 's3://%.zip'
 * region: invalid if empty
 */
function AddNewEntry(props: { onCreate?: (name: string, info: MuleAppType) => void|Promise<void> }) {
  const op = useRef<OverlayPanel>(null);

  const [name, setName] = useState<string>("");
  const [latest, setLatest] = useState<number>(0);
  const [url, setUrl] = useState<string>("");
  const [region, setRegion] = useState<string>("eu-central-1");

  /** which fields have a red outline */
  const [invalid, setInvalid] = useState<[
    name: boolean,
    latest: boolean,
    url: boolean,
    region: boolean,
  ]>([false, false, false, false]);
  /** disables every inputs, not the button */
  const [disabled, setDisabled] = useState(false);

  /** tests each field for validity, add new app if good */
  const sendAndReset = () => {
    const trimmedName = name.trim();
    const trimmedUrl = url.trim();
    const trimmedRegion = region.trim();

    const invalidation: typeof invalid = [
      // invalid name: empty or contains whitespace character
      !trimmedName || !!trimmedName.match(/\s/),
      // invalid version: never
      false,
      // invalid url: empty or not like 's3://%.zip'
      !trimmedUrl || !trimmedUrl.startsWith("s3://") || !trimmedUrl.endsWith(".zip"),
      // invalid region: empty
      !trimmedRegion,
    ];
    if (invalidation.some(_=>_)) return setInvalid(invalidation);

    const a = props.onCreate?.(trimmedName, {
      latest: latest,
      url: trimmedUrl,
      region: trimmedRegion,
    });
    // inputs and confirm button are temporarily disabled;
    // they are re-enable and reset when the promise resolves
    if (a) a.then(() => {
      setDisabled(false);
      setName("");
      setLatest(0);
      setUrl("");
      setRegion("eu-central-1");
    });
    setDisabled(true);
  };

  return (<>
    <div className="p-mt-5 button-add-muleapp">
      <Button label="Ajouter une Application Manuellement" icon="pi pi-plus"
        onClick={e => op.current?.toggle(e, undefined)} className="p-button-raised p-button-text" />
    </div>

    <OverlayPanel ref={op} showCloseIcon dismissable className="p-p-4 p-mb-5 p-col-10 p-lg-5">
      <h5>Ajouter une application</h5>

      <div className="p-fluid p-formgrid p-grid">
        <div className="p-field p-col-8">
            <label htmlFor="name">Nom de l'Application</label>
            <InputText value={name} onChange={e => setName(e.currentTarget.value)}
              placeholder="nom de l'app" disabled={disabled} className={invalid[0] ? "p-invalid" : ""}
              id="add-muleapp-name" aria-describedby="name-help"/>
            <small id="name-help">Sans espaces / charactères blancs (préférablement <code>[0-9a-zA-Z]-._~</code>)</small>
        </div>
        <div className="p-field p-col-4">
            <label htmlFor="latest">Dernière Version</label>
            <InputText value={latest} onChange={e => setLatest(isNaN(+e.currentTarget.value) ? 0 : +e.currentTarget.value)}
              disabled={disabled} className={invalid[1] ? "p-invalid" : ""}
              id="add-muleapp-latest" aria-describedby="latest-help"/>
            <small id="latest-help">Optionnelle - mise à jour automatiquement</small>
        </div>

        <div className="p-field p-col-12">
            <label htmlFor="url">Url générique <code>s3://</code></label>
            <InputText value={url} onChange={e => setUrl(e.currentTarget.value)}
              placeholder="s3://###.zip" disabled={disabled} className={invalid[2] ? "p-invalid" : ""}
              id="add-muleapp-url" aria-describedby="url-help"/>
            <small id="url-help">Utiliser des <code>###</code> à la place du numéro de version</small>
        </div>

        <div className="p-field p-col-4">
            <label htmlFor="region">Region</label>
            <InputText value={region} onChange={e => setRegion(e.currentTarget.value)}
              placeholder="eg eu-central-1" disabled={disabled} className={invalid[3] ? "p-invalid" : ""}
              id="add-muleapp-region"/>
        </div>
        <div className="p-field p-ml-auto p-mt-auto">
          <Button icon="pi pi-check" label="Sauvegarder" disabled={disabled}
            onClick={sendAndReset} />
          </div>
      </div>
    </OverlayPanel>
  </>);
}

/** just some text */
function AdminMuleApps() {
  return <>
    <p>
      Cliquez sur un champ de text pour l'éditer <br />
      Noubliez pas de cliquer sur &laquo;Sauvegarder&raquo; pour appliquer les modifications
    </p>
  </>;
}

/**
 * layout:
 * - `AdminMuleinfo`
 * - `OrderList` of `MuleAppEntry`s
 * - `AddNewEntry`
 * 
 * * if user has read-apps or write-apps roles they can see the apps
 * * if user has write-apps role they can edit/update apps
 */
export default function ScreenMuleApps() {
  const testForRoles = useTestForRoles();

  /** toast used to notify when call is made to update app */
  const toast = useRef<Toast>(null);
  const message = (summary: string, detail: string, severity: 'success'|'info'|'warn'|'error') => toast.current?.show({ severity, summary, detail, life: 5000 });

  /** select every apps as a list of [name, info] for the <OrderList /> */ // eslint-disable-next-line
  const [_, appsList, loaded] = useAPISelect(callServerMeta.use('selectMuleApps'), apps => !apps ? [] : Object.entries(apps).sort((a, b) => a[0].localeCompare(b[0])));

  /** updates (or insert) an app identified by its name with the given info */
  const upsertApp = useAPICaller(callServerMeta.use('patchMuleApp'), (name: string, info: MuleAppType) => {
    message(`'${name}' va être mise à jour`, "", 'info');
    return {
      pointParams: [name, info.latest] as [string, number],
      bodyJson: { url: info.url, region: info.region }
    };
  });
  /** removes an existing app */
  const deleteApp = useAPICaller(callServerMeta.use('deleteMuleApp'), name => ({ pointParams: [name] as [string] }));
  const errorOops = (err: Error) => message("C'est pas bon", `"${err.message}"`, 'error');

  const entry = ([name, info]: [string, MuleAppType]) => (
    <MuleAppEntry editable={testForRoles(['write-apps'])}
      onUpdate={async (_name, _info) => {
        try {
          if (name !== _name) await deleteApp.current?.(name);
          await upsertApp.current?.(_name, _info);
          message(`'${_name}' a été mise à jour`, "", 'success');
        } catch(err) {
          errorOops(err);
        }
      }}
      onDelete={() => {
        deleteApp.current?.(name)
          ?.then(() => message(`'${name}' a été supprimée`, "", 'success'))
          .catch(errorOops);
      }}
      name={name}
      latest={info.latest}
      url={info.url}
      region={info.region} />
  );

  if (!loaded) return <SpinnerText />;

  return (
    <div>
      <Toast ref={toast} />

      {testForRoles(['write-apps']) && <>
        <h3>Administration</h3>
        <AdminMuleApps />
      </>}

      <h1>Informations sur les Applications Mule</h1>

      {!testForRoles(undefined, ['read-apps', 'write-apps'])
        ? <RoleNeeded anyOf={['read-apps', 'write-apps']} />
        : <OrderList value={appsList} itemTemplate={entry}
            className="p-orderlist-hide-controls p-orderlist-more-height p-orderlist-tight p-orderlist-cursor-default p-orderlist-width-available p-mb-6" />
      }

      {testForRoles(['write-apps']) &&
        <AddNewEntry onCreate={(name, info) => upsertApp.current?.(name, info)?.then(() => message(`'${name}' a été ajoutée`, "", 'success')).catch(errorOops)} />}
    </div>
  );
}
