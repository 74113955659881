import { useEffect, useRef } from "react";

// @thx https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
export function useOnClickAway<T extends HTMLElement>(callback: (e: MouseEvent) => void) {
  const callbackRef = useRef<(e: MouseEvent) => void>(); // initialize mutable callback ref
  const innerRef = useRef<T>(null); // returned to client, who sets the "border" element

  // update callback on each render, so second useEffect has most recent callback
  useEffect(() => { callbackRef.current = callback; });

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => document.removeEventListener('mousedown', handleClick);

    function handleClick(e: MouseEvent) {
      if (!innerRef.current || !callbackRef.current) return;
      // to `e.target` or not to `e.currentTarget`, that be the question
      if (e.target instanceof Node && !innerRef.current.contains(e.target))
        callbackRef.current(e);
    }
  }, []); // no dependencies -> stable click listener

  return innerRef; // convenience for client (doesn't need to init ref himself)
}
