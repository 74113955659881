import { createAPISlice } from '../../../helpers/useAPI';

export const callMuleinfo = createAPISlice('muleinfo', "/api/v1/muleinfo", {
  selectMuleinfo: ({ state }) => state,
  selectMuleinfoApps: ({ state }) => {
    if (!state) return;

    const serverKeys = Object.keys(state);
    var appSet = new Set<string>();

    serverKeys.forEach(it => {
      const f = state[it].apps;
      if (f) appSet = new Set([...appSet, ...Object.keys(f)]);
    });

    const apps = [...appSet] as string[];
    const servers = Object.assign({}, ...serverKeys.map(it => ({ [it]: state[it].apps }))) as { [serverKey:string]: {[appName:string]:string} };
    return { apps, servers };
  },
}, {} as api_v1.ServerInfo<api_v1.Muleinfo>);

export default callMuleinfo.reducer;
