import { useState } from 'react';
import { useAPISelect } from '../../../helpers/useAPI';
import { callServerMeta } from '../../../app/commonSlice';
import { callVersions } from './versionsSlice';

import './Versions.module.css';
import { SpinnerText } from '../../Loading';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

/**
 * one row table (header excluded) to show the revision (`versions.txt`) from each server
 * 
 * layout:
 * - table
 *   - first column just says "Module", "timestamp", "revision"
 *   - when a column is selected, some elements update to show difference in revisions' timestamp
 */
export default function ScreenVersions() {
  const [servers, ] = useAPISelect(callServerMeta.use('selectServerMeta'));

  const showServers = ['sid', 'preprod', 'prod']; // FIXME/TODO: .. something (maybe user preferences using auth0? show every server from /meta?)
  /** key of the server we are showing the diff for (so same as `diff.now`) */
  const [diffAroundKey, setDiffAroundKey] = useState('sid');

  /**
   * `data` has the raw results (from API) ie `[serverName]: { revision, version }`
   * `diff` has for property `now` the server that is used for timestamp comparison and `to.[serverName]` the actual difference
   */
  const [data, diff, loaded] = useAPISelect(callVersions.use('selectRevision'), (avec, key) => {
    const updated: { now: string, to: {[key:string]:number} } = { now: key, to: {} };
    if (!avec) return updated;
    for (let to of showServers) {
      let h = 0;
      if (to !== key && avec[to] && avec[key]) {
        const ms = new Date(avec[to].timestamp).getTime() - new Date(avec[key].timestamp).getTime();
        h = ms / (1000 * 60 * 60);
      }
      updated.to[to] = h;
    }
    return updated;
  }, diffAroundKey);

  if (!loaded) return <SpinnerText />;

  /** what's in advance from `diff.now` is shown in green ("success"), what's behind in red ("danger") */
  const getClassDiffFor = (key: string) => {
    return diff.now === key ? '' : diff.to[key] < 0 ? 'p-button-danger' : 'p-button-success';
  };
  /** proper time difference display (eg "-3 jours et 1 heure") */
  const getDisplayDiffFor = (key: string) => {
    if (diff.now === key) return "";

    const days = Math.floor(diff.to[key] / 24);
    const hours = Math.round(diff.to[key] - days*24);

    let r = "";
    if (days) {
      r+= `${0<days?"+":""}${days} jour${1<days?"s":""}`;
      if (hours) r+= ` et ${hours} heure${1<days?"s":""}`;
    } else r = `${0<hours?"+":""}${hours} heure${1<hours?"s":""}`;

    return r;
  };

  /** column header (show server name) with a button to set this server as `diff.now` */
  const getDisplayHeader = (key: string) => (
    <span>
      <Button
        label={servers[key]?.name ?? key}
        onClick={() => setDiffAroundKey(key)}
        className={getClassDiffFor(key)}
        disabled={diff.now === key} />
    </span>
  );
  /** just timestamp and time diff */
  const getDisplayTimestamp = (key: string) => (
    <span>
      <div className="p-d-block">{new Date(data![key].timestamp).toLocaleString()}</div>
      <div className={`p-d-block p-button p-button-text ${getClassDiffFor(key)}`}>{getDisplayDiffFor(key)}</div>
    </span>
  );
  /** just revision number */
  const getDisplayRevision = (key: string) => (
    <span>
      {data![key].revision}
    </span>
  );

  const rows = [
    Object.assign({ 'head': 'timestamp' }, ...showServers.map(key => ({ [key]: getDisplayTimestamp(key) }))),
    Object.assign({ 'head': 'revision' }, ...showServers.map(key => ({ [key]: getDisplayRevision(key) }))),
  ];

  return (
    <div>
      <h1>Versions</h1>
      <DataTable value={rows}>
        <Column field='head' header="Module" style={{ borderRightWidth: "2px" }} />
        {showServers.map(key => <Column key={key} field={key} header={getDisplayHeader(key)} />)}
      </DataTable>
    </div>
  );
}
