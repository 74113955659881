import { useState } from 'react';
import { Wrapper, Topbar, Sidebar, Menu, Content } from '../helpers/layout';

import Home from '../components/Home';
import ScreenVersions from '../components/screens/versions/Versions';
import ScreenMuleinfo from '../components/screens/muleinfo/Muleinfo';
import ScreenMuleApps from '../components/screens/muleapps/MuleApps';
import ScreenJobs from '../components/screens/jobs/Jobs';
import ScreenProjects from '../components/screens/projects/Projects';
import ScreenDoc, { content } from '../components/screens/documentation/Documentation';

import './App.css';
import AuthenticationButton from '../components/authentication/AuthenticationButton';
import UserTopbarCard from '../components/authentication/UserTopbarCard';

export default function App() {
  const [menuToggle, setMenuToggle] = useState(false);
  const toggleMenu = () => setMenuToggle(!menuToggle);

  return (
    <Wrapper masked={menuToggle} onMaskClick={toggleMenu}>
      <Topbar title="Stéthoscope" logo="/logo-t.png" onMenuClick={toggleMenu}>
        <AuthenticationButton />
        <UserTopbarCard />
      </Topbar>

      <Sidebar visible={menuToggle}>
        <Menu
          onEntryClick={toggleMenu}
          category={{ title: "Servers", to: '/'}}
          items={[
            { title: "Versions", to: '/versions' },
            { title: "Mule", to: '/muleinfo', subitems: [
              { title: "Tableau serveur / apps", to: '/muleinfo' },
              { title: "Liste des applications", to: '/muleapps' },
            ] },
            { title: "CircleCI", to: '/projects' },
            { title: "Tâches server", to: '/jobs' },
          ]} />
        <Menu
          onEntryClick={toggleMenu}
          category={{ title: "Documentation", to: '/docs'}}
          items={[
            { title: "API Stethoscope v1", to: '/docs/api' },
            { title: "React hook useAPI", to: '/docs/useAPI' },
            { title: "Autre", to: '/docs/other' },
          ]} />
      </Sidebar>

      <Content
        entries={[
          { path: '/', component: Home },
          { path: '/versions', component: ScreenVersions },
          { path: '/muleinfo', component: ScreenMuleinfo },
          { path: '/muleapps', component: ScreenMuleApps },
          { path: '/jobs', component: ScreenJobs },
          { path: '/projects', component: ScreenProjects },
          { path: '/docs', component: ScreenDoc(content.home, "Accueil Documentation") },
          { path: '/docs/api', component: ScreenDoc(content.api_v1, "API Stéthoscope") },
          { path: '/docs/useAPI', component: ScreenDoc(content.useAPI, <>React hook <code>useAPI</code></>) },
          { path: '/docs/other', component: ScreenDoc(content.other) },
        ]} />
    </Wrapper>
  );
}
