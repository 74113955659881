import { useAuth0 } from '@auth0/auth0-react';
import { createAPISlice } from '../../../helpers/useAPI';

// eslint-disable-next-line react-hooks/rules-of-hooks
const headers = () => useAuth0().getAccessTokenSilently().then(token => ({
  'Authorization': `Bearer ${token}`,
  'Content-Type': "application/json",
}));

type StateType = { jobs: api_v1.ServerInfo<api_v1.Jobs>, tmp: api_v1.ServerInfo<api_v1.Jobs> };

/**
 * to update the jobs correctly, it's needed to iterate through every jobs of the server
 * to update because only the jobs present in 'tmp' should be modified but the
 * rest must be kept as-is
 * 
 * this function starts from the jobs in 'tmp' (for the server in question) and adds
 * the previous ones that where not updated ie not already in the array being build
 */
const updateJobs = (serverFor: string, state: StateType): Partial<StateType> => {
  const oldJobs = state.jobs[serverFor] ?? [];
  // reminder: state can't be modified directly, hence copies the array
  const updatedJobs = [...state.tmp[serverFor]];

  for (let k = 0; k < oldJobs.length; k++)
    if (!updatedJobs.find(j => j.id === oldJobs[k].id))
      updatedJobs.push(oldJobs[k]);

  return { jobs: { ...state.jobs, [serverFor]: updatedJobs }, tmp: {} };
}

export const callJobs = createAPISlice('jobs', "/api/v1/jobs", {
  selectAllJobs: [{ point: "/all/{0}", path: "/jobs" }, ({ state }, serverKey: string) => state?.jobs],
  selectTodoJobs: [{ point: "/todo/{0}", path: "/jobs" }, ({ state }, serverKey: string) => state?.jobs],
  putPushJobs: [{ point: "/push/{0}", path: "/tmp" }, ({ state, invalidate }, serverKey: string) => {
    if (!state) return undefined;

    invalidate(updateJobs(serverKey, state));
    return state.tmp[serverKey];
  }, {
    method: 'PUT',
    headers,
  }],
  putDoneJobs: [{ point: "/done/{0}/{1}", path: "/tmp" }, ({ state, invalidate }, serverKey: string, jobId: string) => {
    if (!state) return undefined;

    invalidate(updateJobs(serverKey, state));
    return state.tmp[serverKey];
  }, {
    method: 'PUT',
    headers,
  }],
  putAbortJobs: [{ point: "/abort/{0}/{1}", path: "/tmp" }, ({ state, invalidate }, serverKey: string, jobId: string) => {
    if (!state) return undefined;

    invalidate(updateJobs(serverKey, state));
    return state.tmp[serverKey];
  }, {
    method: 'PUT',
    headers,
  }],
}, { jobs: {}, tmp: {} } as StateType);

export default callJobs.reducer;
