import { configureStore } from '@reduxjs/toolkit';
import { configureAPICalls } from '../helpers/useAPI';

import commonReducer from './commonSlice';
import jobsReducer from '../components/screens/jobs/jobsSlice';
import versionsReducer from '../components/screens/versions/versionsSlice';
import muleinfoReducer from '../components/screens/muleinfo/muleinfoSlice';
import mulelogsReducer from '../components/screens/muleinfo/mulelogsSlice';
import projectsReducer from '../components/screens/projects/projectsSlice';

export default configureStore({
  reducer: {
    common: commonReducer,
    jobs: jobsReducer,
    versions: versionsReducer,
    muleinfo: muleinfoReducer,
    mulelogs: mulelogsReducer,
    projects: projectsReducer,
  },
});

configureAPICalls({
  preprocess: <T>(r: Response) => selectResponse<T>(r).then(throwOnError)
});

function selectResponse<T>(res: Response) {
  //if (!res.ok) throw - ?
  return res.json() as Promise<api_v1.APIResponse<T>>;
}

const isAPIError = <T>(o: api_v1.APIResponse<T>): o is api_v1.APIError => (o as api_v1.APIError)._error !== undefined;

function throwOnError<T>(res: api_v1.APIResponse<T>) {
  if (isAPIError(res)) throw new Error(res._error.message);
  return res;
}
