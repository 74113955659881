/* eslint-disable react-hooks/exhaustive-deps */

import { ActionCreatorWithPayload, createAction, createSlice, Dispatch } from '@reduxjs/toolkit';
import { useMemo, useRef } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';

//#region some exported types
export type Headers = HeadersInit;
export type Body = BodyInit | null;
export type Request<TMissingParams extends any[] = []> = RequestInit & FillInTheBlanks<TMissingParams> & { bodyJson?: any, setLoaded?: boolean };
export type Maker<T> = ((...useParams: any[]) => (Promise<T> | T)) | Promise<T> | T;

/**
 * If plain string, should be like `"/servers/urls"`;
 * this is equivalent to `{ point: "/server/urls" }`
 */
export type APIPoint = string | {
  /** appended after the root of the APICall */
  point: string,
  /** path to store result in redux store, default is `point` */
  path?: string,
};

export interface FetchPrepare {
  method?: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE';
  headers: Maker<Headers>;
  body?: Maker<Body>;
}

export type InvalidationFunction<TResponse> = (correct: Partial<TResponse>) => void;

//export type Selector<TResponse> = (res: TResponse, ...params: any[]) => any;
export type Selector<TResponse> = (res: { state?: TResponse, invalidate: InvalidationFunction<TResponse> }, ...params: any[]) => any;
export type ProperSelector<TResponse> = [APIPoint, Selector<TResponse>, FetchPrepare?];
export type AnyValidSelector<TResponse> = Selector<TResponse> | ProperSelector<TResponse> | [Selector<TResponse>, FetchPrepare];
export type MultipleValidSelectors<TResponse> = { [selectorName:string]: AnyValidSelector<TResponse> };

/** Result of `APICall.use` */
export interface APISelector<TParams extends any[], TGiven extends any[], TResponse, TSelected> {
  name: string | number | symbol;
  /** Called before using its `.fetch`, may contain react hook (order is maintained) */
  prepare: (lateInit?: (...args: any[]) => Request<ParamsMissing<TParams, TGiven>>) => Promise<boolean | never>;
  /** Dispatched to trigger the fetch call (Delayed object) */
  fetch?: (...args: any[]) => ActionOrUpdateCreator<TResponse>;
  /** `useSelector` used to get a result to return */
  selector: (dispatch: ActionOrUpdateDispatch<TResponse>) => (state: {[name:string]:TResponse}) => TSelected | undefined;
  /** passed to sublector' select (as res.invalidate) */
  invalidate: (dispatch: ActionOrUpdateDispatch<TResponse>) => InvalidationFunction<TResponse>;
  /** `true` when api call finished */
  isLoaded: boolean;
  /** when `true`, useAPICall will fetch for up-to-date result */
  shouldLoad: boolean;
  deps: TGiven;
}
//#endregion

//#region internal helper types
type ParamsPartial<Params extends any[]> = Params extends [infer H, ...infer T] ? [H?, ...ParamsPartial<T>] : Params;
type ParamsMissing<Params extends any[], Partial extends any[]> = Params extends [...Partial, ...infer Z] ? Z : never;

type FillInTheBlanks<TMissingParams extends any[]> = TMissingParams['length'] extends 0 ? { pointParams?: TMissingParams } : { pointParams: TMissingParams };

type ExtractCall<TValidSelector extends AnyValidSelector<TResponse>, TResponse>
  = TValidSelector extends (res?: any, ...params: infer R) => infer S                            ? (...params: R) => S
  : TValidSelector extends [any, (res?: any, ...params: infer R) => infer S, any?] ? (...params: R) => S
  : TValidSelector extends [(res?: any, ...params: infer R) => infer S, any]            ? (...params: R) => S
  : never;
type ExtractParams<TValidSelector extends AnyValidSelector<TResponse>, TResponse> = ExtractCall<TValidSelector, TResponse> extends (...params: infer R) => any ? R : never;
type ExtractReturn<TValidSelector extends AnyValidSelector<TResponse>, TResponse> = ExtractCall<TValidSelector, TResponse> extends (...params: any[]) => infer S ? S : never;

type ActionOrUpdate<TResponse> = { type: string, payload: [string[], TResponse] | Partial<TResponse> };
type ActionOrUpdateDispatch<TResponse> = (a: ActionOrUpdate<TResponse>) => Promise<ActionOrUpdate<TResponse>>;
type ActionOrUpdateCreator<TResponse> = (dispatch: ActionOrUpdateDispatch<TResponse>) => Promise<ActionOrUpdate<TResponse>> | null
//#endregion

//#region internal helper functions
async function makerResult<T extends (Headers | Body)>(maker: Maker<T>, params: any[]): Promise<T> {
  const promiseGuard = <T>(o: Maker<T>): o is Promise<T> => 'number' !== typeof o && 'string' !== typeof o && 'then' in o;

  if ('function' === typeof maker) {
    let res = maker(...params);
    return promiseGuard(res) ? await res : res;
  }

  if (promiseGuard(maker))
    return await maker;

  return maker;
}

/**
 * Inserts params into the api point's string
 * 
 * @returns [api point, state path]
 */
function getPaths(from: APIPoint, params: any[]): [string, string[], string] {
  let str: string, pth: string|undefined;
  if ('string' !== typeof from) {
    str = from.point;
    pth = from.path;
  } else str = from;

  const formatted = (s: string) => s.replace(/{(\d+)}/g, (match: string, n: number) => 'undefined' != typeof params[n] ? encodeURIComponent(params[n].toString()) : match);
  const a = formatted(str);
  const b = pth && formatted(pth);
  const c = (b ?? a).split("/").filter(_=>_);

  return [a, c, a+":"+c.join()];
}

function get(obj: any, path: string[]) {
  let a = obj;
  path.forEach(it => a = a?.[it]);
  return a;
}
function set(obj: any, path: string[], value: any) {
  let last = path.pop()!;
  let a = obj;
  path.forEach(it => a = a[it] ?? (a[it] = {}));
  a[last] = value;
}
//#endregion

//#region default and configs
const _fetch = (a: RequestInfo, b?: RequestInit) => {
  if (_config.verbose)
    console.log(`fetch("${a}"${'string' === typeof b?.body ? ", " + JSON.stringify(JSON.parse(b.body), null, 2) : ""})`);
  return fetch(a, b);
}

const _config: {
  verbose: boolean,
  fetch: (input: RequestInfo, init?: RequestInit) => Promise<Response>,
  preprocess: <TResponse>(response: Response) => (TResponse | Promise<TResponse>)
} = {
  verbose: "development" === process.env.NODE_ENV || "test" === process.env.NODE_ENV,
  fetch: _fetch,
  preprocess: <TResponse>(res: Response) => res.json() as unknown as TResponse
};
//#endregion

//#region class APICall
/**
 * @see createAPICall for usage
 */
class APICall<TResponse, TSelectors extends MultipleValidSelectors<TResponse>> {
  private loaded: Record<string, boolean|undefined> = {};
  private loading: Record<string, boolean|undefined> = {};
  public reducer: {
    action: (state: TResponse, action: {payload:[string[], TResponse]}) => void,
    update: (state: TResponse, action: {payload:TResponse}) => void,
  };
  private action: ActionCreatorWithPayload<[string[], TResponse], string>;
  private update: ActionCreatorWithPayload<Partial<TResponse>, string>;

  private say(something: any, warnLevel?: boolean) {
    if (_config.verbose)
      (warnLevel ? console.warn : console.log)(`[${this.name}] ${something}`);
  }

  /**
   * @param name redux slice name
   * @param root API point root
   * @param selectors set of `[api point, selector function]`s that can be `.use<>()`
   */
  constructor(
    public name: string,
    private root: string,
    private selectors: TSelectors,
  ) {
    this.reducer = {
      action: (state, { payload }) => {
        if ("-" !== payload[0][0]) {
          this.say(`setting ${!payload[0].length ? "state" : `'${payload[0].join(".")}'`}`);
          if (!payload[0].length) Object.assign(state, payload[1]);
          else set(state, payload[0], Object.assign(get(state, payload[0]) || {}, payload[1]));
        } else this.say("received result marked as do not store");
      },
      update: (state, { payload }) => {
        this.say("correcting store");
        Object.assign(state, payload);
      }
    };
    this.action = createAction(this.name + "/action"); //as ActionCreatorWithPayload<[string[], TResponse], string>;
    this.update = createAction(this.name + "/update") as ActionCreatorWithPayload<Partial<TResponse>, string>;
  }

  /**
   * Fetches the api at the given `uri`,
   * `path` specify where to dispatch the result in the store,
   * and `key` under what the result should be cached
   */
  private fetchAsync([uri, path, key]: [string, string[], string], init: Request, dispatch: ActionOrUpdateDispatch<TResponse>) {
    if (!this.shouldLoad(key)) return null; //new Promise((resolve, _reject) => resolve(null));
    this.say(`fetching '${uri}'`);

    this.setLoading(key);
    const preprocess = (res: Response) => _config.preprocess<TResponse>(res);
    const process = (res: TResponse) => {
      this.say(`processing '${uri}'`);
      this.setLoaded(key, init.setLoaded);
      return dispatch(this.action([path, res]));
    };
    //const error = (e: Error): never => {
    //  this.say(`encountered error (${e.name}) while fetching, there is likely another error visible above this one`);
    //}

    return _config.fetch(this.root + uri, init)
      .then(preprocess)
      .then(process)
      /*.catch(error)*/; // TODO/MAYBE
  }

  private shouldLoad(key: string) {
    return !this.loaded[key] && !this.loading[key];
  }

  private isLoaded(key: string) {
    return !!this.loaded[key];
  }

  private setLoading(key: string) {
    this.loading[key] = true;
    this.loaded[key] = false;
  }

  private setLoaded(key: string, set?: boolean) {
    this.loading[key] = false;
    if (undefined !== set) this.loaded[key] = !!set;
  }

  public invalidate<TName extends keyof TSelectors, TParams extends ExtractParams<TSelectors[TName], TResponse>, TGiven extends ParamsPartial<TParams>>(selectorName: TName, ...params: TGiven) {
    const sublector = this.selectors[selectorName] as ProperSelector<TResponse>;
    const [, , key] = getPaths(sublector[0], params);
    this.say(`(${selectorName}) invalidated`);
    this.setLoaded(key, false);
    return (dispatch: Dispatch<any>, correct: Partial<TResponse>) => {
      this.say(`(${selectorName}) revalidated`);
      this.setLoaded(key, true);
      dispatch(this.update(correct));
    };
  }

  /**
   * Params are inserted in `point`, replacing any valid `{number}` (similar to a string formatting)
   * 
   * @remark
   * If the selector specifies a headers maker and/or a body maker, they will both be called sequentially (in this order)
   * that is **even if headers fails (throws) body is called** (so that react hooks may be used in there)
   * but if any maker fails (throws) the prepare step will be cut short and the fetch will not occur.
   */
  public use<TName extends keyof TSelectors, TParams extends ExtractParams<TSelectors[TName], TResponse>, TGiven extends ParamsPartial<TParams>, TSelected extends ExtractReturn<TSelectors[TName], TResponse>>(selectorName: TName, ...params: TGiven) {
    const sublector = this.selectors[selectorName] as ProperSelector<TResponse>;

    let finalParams = params as any[];

    const select = sublector[1];
    const uri_path_key = getPaths(sublector[0], finalParams);

    const r: APISelector<TParams, TGiven, TResponse, TSelected> = {
      name: selectorName || "(default)",
      prepare: async lateInit => { // TODO: if isLoaded or maybe !shouldLoad, abort prepare step before defining fetch
        let init: Request = {};
        const makeHeaders = sublector[2]?.headers;
        const makeBody = sublector[2]?.body;

        if (makeHeaders || makeBody || sublector[2]?.method) {
          let didFail = false;
          const expectMayFail = (e: Error) => {
            this.say(`(${selectorName}) ${e.message}`, true);
            didFail = true;
            return undefined;
          };

          init.headers = makeHeaders ? await makerResult(makeHeaders, finalParams).catch(expectMayFail) : undefined;
          init.body = makeBody ? await makerResult(makeBody, finalParams).catch(expectMayFail) : undefined;
          init.method = sublector[2]?.method;

          if (didFail) return false;
        }

        //if (isLoaded || !shouldLoad) return false;
        //this.say("'" + selectorName + "' ready");

        r.fetch = (...args) => {
          let that = uri_path_key;
          if (!!lateInit) {
            this.say("late init");
            const late = lateInit(...args);
            if (late.pointParams) that = getPaths(sublector[0], finalParams = [...finalParams, ...late.pointParams]);
            if (late.bodyJson) late.body = JSON.stringify(late.bodyJson);
            init = Object.assign(init, late);
          } else init.setLoaded = true;
          this.say("fetch ready");
          return this.fetchAsync.bind(this, that, init);
        };
        return true;
      },
      selector: dispatch => state => select({ state: state[this.name], invalidate: r.invalidate(dispatch) }, ...finalParams),
      invalidate: dispatch => correct => dispatch(this.update(correct)),
      isLoaded: this.isLoaded(uri_path_key[2]),
      shouldLoad: this.shouldLoad(uri_path_key[2]),
      deps: params,
    };
    return r;
  }
}
//#endregion

//#region main exports
/**
 * React hook used to invoke the given APICall
 * 
 * @returns [selected, loaded]
 * 
 * selected: the selected data, as result of useSelector
 * 
 * loaded: true if selected was loaded and can be used
 * 
 * @example
 * const [selected, loaded] = useAPISelect(callUsers.use<string[]>('selectUsernames'));
 * // selected: string[]
 * // - - default select - -
 * const [selected, loaded] = useAPISelect(callUsers.use<ResponseType>());
 * // selected: ResponseType
 */
export function useAPISelect<TParams extends any[], TResponse, TSelected>(useSelect: APISelector<TParams, TParams, TResponse, TSelected>): [TSelected, boolean];

/**
 * React hook used to invoke the given APICall, additional `formatter` provide up-to-date formatted data
 * 
 * @param formatter formatter function taking the result of the selection and the `deps`
 * @param deps behave similarly to `useMemo`
 * 
 * @returns [selected, formatted, loaded]
 * 
 * selected: the selected data, as result of useSelector
 * 
 * formatted: the formatted data, equivalent to calling formatter(selected, ...deps) but properly updated
 * 
 * loaded: true if selected was loaded and can be used
 * 
 * @example
 * // .. ordering = .., filtering = ..
 * const formatUsernamesToList = (selected: string[], ordering, filtering) => <ul>{selected.map(it => <li>it</li>)}</ul>;
 * const [selected, formatted, loaded] = useAPISelect(callUsers.use<string[]>('selectUsernames'), formatUsernamesToList, [ordering, filtering]);
 * // selected: string[]
 * // formatted: JSX.Element
 */
export function useAPISelect<TParams extends any[], TResponse, TSelected, TFormatted, TDeps extends any[]>(useSelect: APISelector<TParams, TParams, TResponse, TSelected>, formatter: (sel?: TSelected, ...deps: TDeps) => TFormatted, ...deps: TDeps): [TSelected, TFormatted, boolean];

export function useAPISelect<TParams extends any[], TResponse, TSelected, TFormatted, TDeps extends any[]>(useSelect: APISelector<TParams, TParams, TResponse, TSelected>, formatter?: (sel?: TSelected, ...deps: TDeps) => TFormatted, ...deps: TDeps): any[] {
  const dispatch = useDispatch<(_?: ActionOrUpdateCreator<TResponse>) => Promise<ActionOrUpdate<TResponse>> | null>();

  useSelect.prepare().then(yes => {
    if (yes) {
      const proceed = useSelect.fetch?.();
      if (proceed) dispatch(proceed);
    }
  });

  const selected = useSelector(useSelect.selector(dispatch as any));
  const loaded = useSelect.isLoaded;

  const formatted = useMemo(() => loaded ? formatter?.(selected, ...deps) : undefined, [selected, deps, loaded]);
  return !formatter ? [selected, loaded] : [selected, formatted, loaded];
}

/**
 * React hook used to invoke other http verbs (POST, PUT, PATCH, DELETE) with headers and body
 * 
 * @param fetchInit function returning the RequestInit to use
 * 
 * @returns execute
 * 
 * call this ref's `.current` function with the same parameter signature as `fetchInit` to execute the api call
 * (a same execute can be called multiple times) ; `execute.current` may be undefined when the usage (defined
 * in the slice) rely on promises to build `headers` (or `body`) in which case the `execute.current` will be
 * set only after every promise is resolved
 * 
 * @example
 * // .. someUser = ..
 * const setName = useAPICaller(callUsers.use('updateUsername', someUser.id), (newName: string) => ({ body: JSON.stringify({name:newName}) }));
 * // - -
 * <button onClick={() => setName.current?.("Wholdall Georges")}>Set New Name</button>
 */
export function useAPICaller<TParams extends any[], TGiven extends any[], TResponse, TSelected, TArgs extends any[]>(useSelect: APISelector<TParams, TGiven, TResponse, TSelected>, fetchInit: (...args: TArgs) => Request<ParamsMissing<TParams, TGiven>>): React.MutableRefObject<((...args: TArgs) => (Promise<TSelected>|undefined)) | undefined> {
  const dispatch = useDispatch<(_?: ActionOrUpdateCreator<TResponse>) => Promise<ActionOrUpdate<TResponse>> | null>();

  const execute = useRef<(...args: TArgs) => (Promise<TSelected>|undefined)>()
  const store = useStore();

  useSelect.prepare(fetchInit as (...args: any[]) => Request<ParamsMissing<TParams, TGiven>>).then(yes => {
    if (yes) {
      const select = () => useSelect.selector(dispatch as any)(store.getState())!;
      execute.current = (...args: TArgs) => dispatch(useSelect.fetch?.(...args))?.then(select);
    }
  });

  return execute;
}

/**
 * Use this function to create a set of selector that acts around the result given by corresponding API calls
 * Every calls for this APICall are made under a common `root` (for example `/api/v1/users`)
 * Selectors can give a specific point to fetch from (for example `/{0}/messages`)
 * `"{0}"` is a parameter that will then be required when calling using this selector
 * 
 * To make an API call and use the result, use the react hooks `useAPISelect` or `useAPICaller`
 * 
 * @see useAPISelect will make the fetch when necessary and provide the result from the redux store passed through selector and formatter
 * @see useAPICaller will make a function that will fetch when necessary, especially for requests with PUT/PUSH/PATCH/UPDATE/DELETE...
 * @see createAPISlice to avoid boilerplate code
 * 
 * @example
 * type UserType = { id:number, name:string, mail:string };
 * type ResponseType = { [username:string]: UserType };
 * 
 * export const callUsers = createAPICall<ResponseType>('user', "/api/v1/users")({
 *   selectUsernames: (apiResponse: ResponseType) => Object.keys(apiResponse),  // same as ["/", <*-*>]
 *   selectUsermails: (apiResponse: ResponseType) => apiResponse,               // same as ["/", <*-*>]
 *   updateUsername: ["/{0}/setName", _=>_, { method: 'PATCH', headers: {'Content-Type':"application/json"} }]
 * });
 * 
 * export const userSlice = createSlice({
 *   name: callUsers.name,
 *   initialState: {},
 *   reducers: callUsers.reducer,
 * });
 * export default userSlice.reducers;
 * 
 * ---
 * 
 * function UserList(props) {
 *   const [selected, loaded] = useAPISelect(callUsers.use<string[]>('selectUsernames'));
 *   
 *   // - - with formatting - -
 *   
 *   const formatUsernamesToList = (selected: string[], ordering, filtering) => <ul>{selected.map(it => <li>it</li>)}</ul>;
 *   const [selected, formatted, loaded] = useAPISelect(callUsers.use<string[]>('selectUsernames'), formatUsernamesToList, [ordering, filtering]);
 *   
 *   // - - default select - -
 *   
 *   const [selected, loaded] = useAPISelect(callUsers.use<ResponseType>());
 * }
 */
export function createAPICall<TResponse>(name: string, root: string) {
  return <TSelectors extends MultipleValidSelectors<TResponse>>(selectors: TSelectors) => {
    const guard = (o: AnyValidSelector<TResponse> & any[]): o is [Selector<TResponse>, FetchPrepare] => 'headers' in o[1];
    const mapped: { [selectorName:string]: ProperSelector<TResponse> } = {};
    Object.entries(selectors).forEach(([selectorName, sel]) => mapped[selectorName] = Array.isArray(sel) ? guard(sel) ? ["/", ...sel] : sel : ["/", sel]);
    return new APICall<TResponse, TSelectors>(name, root, mapped as any);
  };
}

/**
 * Encapsulates the call to createAPICall and createSlice into a single object that can be treated both as
 * the usual Redux slice and a APICall object, that is it can be `.use` with one of the hooks `useAPISelect` and `useAPICaller`.
 * 
 * @see useAPISelect will make the fetch when necessary and provide the result from the redux store passed through selector and formatter
 * @see useAPICaller will make a function that will fetch when necessary, especially for requests with PUT/PUSH/PATCH/UPDATE/DELETE...
 * @see createAPICall for underlying APICall object creation
 * @see createSlice for underlying Redux slice creation
 * 
 * @example
 * type UserType = { id:number, name:string, mail:string };
 * type ResponseType = { [username:string]: UserType };
 * 
 * export const callUsers = createAPISlice('user', "/api/v1/users", {
 *   selectUsernames: (apiResponse: ResponseType) => Object.keys(apiResponse),  // same as ["/", <*-*>]
 *   selectUsermails: (apiResponse: ResponseType) => apiResponse,               // same as ["/", <*-*>]
 *   updateUsername: ["/{0}/setName", _=>_, { method: 'PATCH', headers: {'Content-Type':"application/json"} }]
 * }, {} as ResponseType); // empty initial state
 * 
 * export default callUsers.reducers;
 * 
 * // - - when configuring store - -
 * import userReducer from './userSlice';
 * 
 * export default configureStore({
 *   reducer: {
 *     // ...
 *     user: userReducer,
 *     // ...
 *   },
 * });
 */
export function createAPISlice<TResponse, TSelectors extends MultipleValidSelectors<TResponse>, TheName extends string>(name: TheName, root: string, uses: TSelectors, initial?: TResponse) {
  const call = createAPICall<TResponse>(name, root)(uses);
  const slice = createSlice({ name, initialState: initial ?? {} as TResponse, reducers: call.reducer as any });
  return Object.assign(call, slice);
}

export function configureAPICalls(config: Partial<typeof _config>) {
  if (config.verbose) _config.verbose = config.verbose;
  if (config.fetch) _config.fetch = config.fetch;
  if (config.preprocess) _config.preprocess = config.preprocess;
}
//#endregion
