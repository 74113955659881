import { createAPISlice } from "../../../helpers/useAPI";

type PapertrailEvent = {
  id: string,
  generated_at: string,
  received_at: string,
  display_received_at: string,
  source_name: string,
  hostname: string,
  source_id: string,
  source_ip: string,
  facility: string,
  severity: string,
  program: string,
  message: string,
};
type PapertrailSearchRes = {
  events: PapertrailEvent[],
  min_id: string,
  max_id: string,
};

const apiToken = process.env.REACT_APP_PAPERTRAIL_API_TOKEN!;
const headers = {
  'Content-Type': "application/json",
  'X-Papertrail-Token': apiToken,
};

export type StreamedLogs = { logs: string[], forward: string };

export const callMulelogs = createAPISlice('mulelogs', "https://papertrailapp.com/api/v1", {
  /**
   * @param params [Papertrail system (api_v1 server meta's host), Papertrail program (api_v1 mule app)]
   * @returns {StreamedLogs} `StreamedLogs`
   */
  selectLogtailBegin: [
    { point: "/events/search.json?system_id={0}&q=program%3A{1}&limit=10", path: "/latest/{0}/{1}" },
    ({ state }, system_id: string, program: string) => {
      if (!state) return
      const sys = state.latest[system_id]; if (!sys) return;
      const res = sys[program]; if (!res) return;
      const logs = res.events.map(it => it.message);
      return { logs, forward: res.max_id } as StreamedLogs;
    }, { headers }
  ],
  /**
   * @param params [Papertrail system (api_v1 server meta's host), Papertrail program (api_v1 mule app)]
   * @param lateInit.pointParams [`forward` of last `selectLogtailBegin` or `selectLogtailContinue`]
   * @returns {StreamedLogs} `StreamedLogs`
   */
  selectLogtailContinue: [
    { point: "/events/search.json?system_id={0}&min_id={2}&q=program%3A{1}&limit=10", path: "/latest/{0}/{1}" },
    ({ state }, system_id: string, program: string, forward: string) => {
      if (!state) return;
      const sys = state.latest[system_id]; if (!sys) return;
      const res = sys[program]; if (!res) return;
      const logs = res.events.map(it => it.message);
      return { logs, forward: res.max_id } as StreamedLogs;
    }, { headers }
  ],
}, { latest: {} as { [system:string]: { [program:string]: PapertrailSearchRes|undefined } | undefined } });

export default callMulelogs.reducer;
