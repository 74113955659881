import { useMemo, useRef, useState } from 'react';
import { useTestForRoles } from '../../../helpers/useTestForRoles';
import { useAPICaller, useAPISelect } from '../../../helpers/useAPI';
import { callServerMeta } from '../../../app/commonSlice';
import { callJobs } from './jobsSlice';

import './Jobs.module.css';
import { SpinnerText } from '../../Loading';
import { RoleNeeded } from '../../authentication/RoleNeeded';
import { TabView, TabPanel } from 'primereact/tabview';
import { OrderList } from 'primereact/orderlist';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Chip } from 'primereact/chip';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';

type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

const iconForStatus = [
  "pi-question-circle",    // api_vi.JobStatus.Unknown
  "pi-exclamation-circle", // api_vi.JobStatus.Given
  "pi-spin pi-spinner",    // api_vi.JobStatus.Waiting
  "pi-check-circle",       // api_vi.JobStatus.Done
  "pi-ban",                // api_vi.JobStatus.Aborted
];
const textForStatus = [
  "état inconu",    // api_vi.JobStatus.Unknown
  "nouvelle tâche", // api_vi.JobStatus.Given
  "en attente...",  // api_vi.JobStatus.Waiting
  "finie",          // api_vi.JobStatus.Done
  "abandonée",      // api_vi.JobStatus.Aborted
];

const jobTypes = ['none', 'deploy'];

/**
 * a single entry in the tab view (each tab is a server ie a list of jobs is shown)
 * 
 * layout:
 * - search bar
 *   - by type (drop down)
 *   - by arguments (text input)
 *   - by status (button set)
 * - list of jobs (see the `entry` function for the body)
 * - overlay panel (unique one, not always visible, moved to location when needed)
 */
function ServerJobsEntry(props: {
  jobs: api_v1.Jobs,
  /** should only be true when the `jobs` prop can be used safely */
  loaded: boolean,
  /** can the user edit jobs? (mark as done/aborted...) */
  canEditJobs: boolean,
  markJobDone: (id: string) => void,
  markJobAbort: (id: string) => void
}) {
  // everything related to the overlay panel shown when clicking on the button of on job
  const pan = useRef<OverlayPanel>(null);
  /** the job to show in the overlay panel */
  const [panJob, setPanJob] = useState<ArrayElement<typeof props.jobs>>();
  /** set the job to show and toggle the panel at the right location */
  const showJobPan = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, it: ArrayElement<typeof props.jobs>) => {
    setPanJob(it);
    pan.current?.toggle(e, undefined);
  };

  /**
   * a single entry is one line (ie one job) in the list
   * 
   * layout:
   * - a button (toggles the overlay panel)
   * - the list of arguments (rendered as chips)
   * - the status (icon)
   * - result (or default "pas de résultat")
   */
  const entry = (it: ArrayElement<typeof props.jobs>) => (
    <div className="p-d-flex p-ai-center">
      <div className="tooltip p-tooltip p-mr-3">
        <Button label={it.type} onClick={e => showJobPan(e, it)} className="p-button-outlined p-button-info" style={{ width: "7rem" }} aria-haspopup aria-controls="overlay_panel" />
        <span className="tooltip-text p-tooltip-text">{it.id}</span>
      </div>

      <div style={{ flexGrow: 1 }}>
        {!it.args?.length ? "(no args)" : it.args?.map((a, k) => <Chip className="p-mr-2" label={a} key={k} />)}
      </div>

      <div className="p-ml-auto p-d-flex p-ai-center">
        <Tooltip target=".tt-target-status" />
        <i className={`tt-target-status p-d-none p-d-sm-block p-mr-2 pi ${iconForStatus[it.status]}`} style={{ fontSize: "1.3rem" }} data-pr-tooltip={textForStatus[it.status]}></i>
        <div className="p-d-none p-d-md-flex p-text-nowrap p-text-truncate" style={{ width: "14rem" }}>{it.result || "pas de résultat"}</div>
      </div>
    </div>
  );

  // everything related to sorting/filtering the list of jobs to show
  /** if not empty string, favor jobs with same task */
  const [sortedByType, setSortedByType] = useState('');
  /** only keeps jobs which argument list has an entry containing a term of the search (`sortedByType` is split into terms on spaces) */
  const [filterWithArgs, setFilterWithArgs] = useState("");
  /** if not negative string, favor jobs with same status */
  const [sortedByStatus, setSortedByStatus] = useState(/*api_vi.JobStatus.Given*/1);

  /** the sorted list of jobs */
  const lst = useMemo(() => {
    const terms = filterWithArgs.split(" ").filter(_=>_);
    const byTerms = terms.length;
    const byStatus = -1 < sortedByStatus;
    const byType = '' !== sortedByType;
    return (
      // if search criteria are defined
      byTerms || byStatus || byType
        ? props.jobs.filter(it =>
            // if search terms are entered (space separated, filter empty strings)
            (!byTerms || it.args?.some(a => !a || terms.some(t => a.includes(t))))
            // or if the status is the same as the one selected
            && (!byStatus || sortedByStatus === it.status)
            // or if the type is the same as the one selected
            && (!byType || sortedByType === it.type)
          )
        : [...props.jobs] // (need to copy props for .sort - inplace)
      )
      // in any case, sort by date
      .sort((a, b) => new Date(b.status_on ?? b.given_on).getTime() - new Date(a.status_on ?? a.given_on).getTime());
  }, [props.jobs, sortedByType, filterWithArgs, sortedByStatus]);

  if (!props.loaded) return <SpinnerText />;
  if (!props.jobs.length) return <div className="p-text-center p-mt-4">Pas de tâche...</div>;

  return (<>
    <div className="p-d-flex p-p-3 card p-ai-center">
      <span className="p-pr-3">Trier...</span>
      <Dropdown value={sortedByType} options={jobTypes} onChange={e => setSortedByType(e.value)} placeholder="Par type" className="searchbar-by-type" />
      <span className="p-pl-3" />
      <InputText style={{ flexGrow: 1 }} type="text" className="searchbar-by-args" placeholder="Avec arguments" onChange={(e: any) => setFilterWithArgs(e.target.value)} />
      <span className="p-pr-3 p-pl-3">Par status</span>
      <span className="p-buttonset p-ml-auto p-button-help">
        {iconForStatus.map((n, k) =>
          <Button key={k}
            icon={"pi "+n.replace("pi-spin ", "")}
            className={sortedByStatus === k ? "p-disabled" : ""}
            tooltip={textForStatus[k]}
            tooltipOptions={{ position: "top" }}
            onClick={() => setSortedByStatus(k)} style={{ width: "auto" }} />)
        }
      </span>
    </div>

    {!!lst.length && // if there are jobs to show
      <OrderList value={lst} itemTemplate={entry} className="p-orderlist-hide-controls p-orderlist-more-height" />}
    {lst.length < props.jobs.length && // if there are hidden jobs
      <p className="p-text-center p-mt-4">(+ {props.jobs.length - lst.length} tâche{props.jobs.length-lst.length < 2 ? "" : "s"} non affichées
        &nbsp;&mdash; <a href="-" onClick={e => { e.preventDefault(); setSortedByType(''); setFilterWithArgs(""); setSortedByStatus(-1) }}>afficher tout</a>)
      </p>}

    <OverlayPanel ref={pan} showCloseIcon id="overlay_panel">
      {!panJob ? "oups" : /* the panel shows when clicking the button of an entry */ (<>
        <div>{"Status : " + textForStatus[panJob.status]}</div>
        <Divider />
        <div>{"Donnée le " + new Date(panJob.given_on).toLocaleString()}</div>
        <div>{panJob.status_on ? "Réalisée le " + new Date(panJob.status_on).toLocaleString() : "Non réalisée"}</div>

        {props.canEditJobs && /*api_vi.JobStatus.Done*/3 !== panJob.status && /*api_vi.JobStatus.Aborted*/4 !== panJob.status && (<>
          <Divider />
          <div className="p-d-flex">
            <Button label="Réalisée" icon="pi pi-check-square" onClick={() => { pan.current?.hide(); props.markJobDone(panJob.id); }} className="p-button-outlined p-button-success p-mr-1" tooltip="Marquer la tâche comme réalisée" tooltipOptions={{position: 'bottom'}} />
            <Button label="Annuler" icon="pi pi-trash" onClick={() => { pan.current?.hide(); props.markJobAbort(panJob.id); }} className="p-button-outlined p-button-danger p-ml-auto" tooltip="Marquer la tâche comme annulée" tooltipOptions={{position: 'bottom'}} />
          </div>
        </>)}
      </>)}
    </OverlayPanel>
  </>);
}

/**
 * layout: a tab view of `ServerJobsEntry`s
 * 
 * * if user has read-jobs or write-jobs roles they can see the jobs
 * * if user has write-jobs role they can edit/update jobs
 */
export default function ScreenJobs() {
  const [servers, loaded] = useAPISelect(callServerMeta.use('selectServerMeta'));
  const serverKeys = Object.keys(servers);

  const testForRoles = useTestForRoles();

  /** toast used to notify when call is made to update job */
  const toast = useRef<Toast>(null);
  const message = (summary: string, detail: string, severity: 'success'|'info'|'warn'|'error') => toast.current?.show({ severity, summary, detail, life: 5000 });

  const [selected, select] = useState(0);
  const selectedKey = serverKeys[selected] || 'sid';

  /** select every jobs _for the selected server only_ */ // eslint-disable-next-line
  const [_, jobs, jobsLoaded] = useAPISelect(callJobs.use('selectAllJobs', selectedKey), (it, key) => it?.[key], selectedKey);

  const putJobDone = useAPICaller(callJobs.use('putDoneJobs', selectedKey), (id: string) => {
    message("La tâche va être marquée comme effectuée", `(serverKey : ${selectedKey}, jobId : ${id})`, 'info');
    return { pointParams: [id] as [string] };
  });
  const putJobAbort = useAPICaller(callJobs.use('putAbortJobs', selectedKey), (id: string) => {
    message("La tâche va être annulée", `(serverKey : ${selectedKey}, jobId : ${id})`, 'info');
    return { pointParams: [id] as [string] };
  });
  const signalPutStatus = (res?: api_v1.Jobs) => !res
    ? message("C'est pas bon", "(pas de réponse, mais pas d'erreur non plus...)", 'error')
    : message("C'est bon", `(ID de la tâche : ${res![0].id}, nouveau status : ${textForStatus[res![0].status]})`, 'success');
  const errorPutStatus = (err: Error) => message("C'est pas bon", `"${err.message}"`, 'error');

  if (!loaded) return <SpinnerText />;

  return (
    <div>
      <Toast ref={toast} />

      <h1>{"Tâches du server " + (servers[selectedKey].name ?? selectedKey)}</h1>

      {!testForRoles(undefined, ['read-jobs', 'write-jobs'])
        ? <RoleNeeded anyOf={['read-jobs', 'write-jobs']} />
        : <TabView activeIndex={selected} onTabChange={e => select(e.index)}>
            {serverKeys.map(key => (
              <TabPanel header={servers[key].name ?? key} key={key}>
                <ServerJobsEntry
                  jobs={jobs ?? []}
                  loaded={jobsLoaded}
                  canEditJobs={testForRoles(['write-jobs'])}
                  markJobDone={id => putJobDone.current?.(id)?.then(signalPutStatus).catch(errorPutStatus)}
                  markJobAbort={id => putJobAbort.current?.(id)?.then(signalPutStatus).catch(errorPutStatus)} />
              </TabPanel>
            ))}
          </TabView>
      }
    </div>
  );
}
