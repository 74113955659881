import { useAuth0 } from "@auth0/auth0-react";

export default function UserTopbarCard() {
  const { user, isAuthenticated, error } = useAuth0();

  if (error) {
    console.error("[Auth0]: " + error?.message);
    console.error(error);
  }

  return !isAuthenticated || !user ? null : (
    <div className="p-as-stretch p-d-inline-flex p-flex-row">
      <img src={user.picture} alt="Profile" className="p-as-center p-p-1" style={{ width: "4rem", height: "4rem" }} />
      <div className="p-d-none p-d-md-inline-flex p-flex-column p-jc-center p-ml-2">
        <span>{user.name}</span>
        <span>{user.email}</span>
      </div>
    </div>
  );
};
