import './Documentation.module.css';

import { useTestForRoles } from '../../../helpers/useTestForRoles';

import { RoleNeeded } from '../../authentication/RoleNeeded';

import home from './content/home';
import api_v1 from './content/api_v1';
import useAPI from './content/useAPI';
import other from './content/other';

const content = { home, api_v1, useAPI, other };
export { content };

export default function ScreenDoc(Content: () => JSX.Element, h1?: string | JSX.Element) {
  const testForRoles = useTestForRoles();

  return () => (
    <div>
      <h1>{h1}</h1>
      {!testForRoles(undefined, ['read-docs'])
        ? <RoleNeeded allOf={['read-docs']} />
        : <Content />
      }
    </div>
  );
}
