import React, { useState } from 'react';
import { InputSwitch } from 'primereact/inputswitch';
import { Helmet } from 'react-helmet-async';
import { Route, NavLink, Switch } from 'react-router-dom';

type HasChildren = { children?: React.ReactNode };
type MenuEntry = { title: string, to: string, subitems?: MenuEntry[] };
type ContentEntry = { path: string, component?: React.ComponentType<any> };

export function Wrapper(props: HasChildren & { masked?: boolean, onMaskClick?: ()=>void }) {
  return (
    <div className={`layout-wrapper theme-material ${props.masked ? "blocked-scroll" : ""}`}>
      {props.children}
      <div className={`layout-mask ${props.masked ? "layout-mask-active" : ""}`} onClick={props.onMaskClick}></div>
    </div>
  );
}

export function Topbar(props: HasChildren & { title: string, logo: string, onMenuClick: ()=>void }) {
  const [themeName, setThemeName] = useState(localStorage.getItem('stethoscope.preferences.theme') ?? "md-light-indigo");
  const [themeSwitch, setThemeSwitch] = useState(themeName === "md-light-indigo");

  const switchTheme = () => {
    const niwTheme = themeSwitch ? "md-dark-indigo" : "md-light-indigo";
    localStorage.setItem('stethoscope.preferences.theme', niwTheme);
    setThemeName(niwTheme);
    setThemeSwitch(!themeSwitch);
  };

  return (
    <div className="layout-topbar p-d-flex p-flex-row p-ai-center">
      <button type="button" className="p-link menu-button" onClick={props.onMenuClick}><i className="pi pi-bars"></i></button>
      <img className="p-d-none p-d-lg-inline-flex p-as-stretch p-m-2" src={props.logo} alt="/*-*/" />
      <h2 className="p-as-center p-m-2 p-d-none p-d-sm-block">{props.title}</h2>
      <div className="p-as-stretch p-p-2 p-d-flex p-ai-center" style={{ flexGrow: 1 }}>
        {props.children}
      </div>
      <div className="p-d-none p-d-sm-flex p-ml-auto p-flex-row p-ai-center p-px-4">
        <Helmet>
          <link id="theme-link" rel="stylesheet" href={`/themes/${themeName}/theme.css`} />
        </Helmet>
        <i className={`p-p-3 pi pi-${themeSwitch ? "sun" : "moon"}`} style={{ fontSize: "2em" }}></i>
        <InputSwitch className="" checked={themeSwitch} onChange={switchTheme} />
      </div>
    </div>
  );
}

export function Sidebar(props: HasChildren & { visible: boolean }) {
  return (
    <div className={`layout-sidebar ${props.visible ? "active" : ""}`} role="navigation">
      <div className="p-d-md-none p-pt-6"></div>
      <div className="layout-menu" role="menubar">
        {props.children}
      </div>
    </div>
  );
}

function ToggleableSub(props: { parent: MenuEntry, subitems: MenuEntry[], onEntryClick?: (entry:MenuEntry)=>void }) {
  const [state, setState] = useState<0|1|2|-1>(0);

  const b = "p-toggleable-content";
  const d = 0 < state ? "-enter" : "-exit";
  const a = 2 % state ? "-done" : "-active";
  const classes = `${b} ${b+d} ${b+d+a}`;

  // TODO: not necessary, but when setting state
  // if setting to 1 -> set to 2 1s after
  // if setting to -1 -> set to 0 .45s after
  // (and add "${2 % state ? " pi-minus " : " "}" before "p-ml-auto")

  return (<>
    <button className="p-link" onClick={() => setState(0 < state ? -1 : 1)}>
      {props.parent.title}
      <i className={`pi pi-angle-${0 < state ? "up" : "down"} p-ml-auto`}></i>
    </button>
    {!!state && <div className={classes}>
      <ul>{props.subitems.map((it, k) =>
        <li role="menuitem" key={`${props.parent.title}>${it.title}@${it.to}`}>
          <NavLink role="menuitem" activeClassName="p-disabled" to={it.to} onClick={()=>props.onEntryClick?.(it)}>{it.title}</NavLink>
        </li>)}
      </ul>
    </div>}
  </>);
}

export function Menu(props: HasChildren & { category: MenuEntry, items: MenuEntry[], onEntryClick?: (entry:MenuEntry)=>void }) {
  return (
    <div>
      <div className="menu-category">
        <NavLink role="menuitem" to={props.category.to} onClick={()=>props.onEntryClick?.(props.category)}>{props.category.title}</NavLink>
      </div>
      <div className="menu-items">
        {props.items.map(it => it.subitems
          ? <ToggleableSub key={`${it.title}@${it.to}`} parent={it} subitems={it.subitems} onEntryClick={props.onEntryClick} />
          : <NavLink key={`${it.title}@${it.to}`} role="menuitem" activeClassName="p-disabled" to={it.to} onClick={()=>props.onEntryClick?.(it)}>{it.title}</NavLink>
        )}
      </div>
      {props.children}
    </div>
  );
}

export function Content(props: HasChildren & { entries: ContentEntry[] }) {
  return (
    <div className="layout-content">
      <div className="content-section">
        <Switch>
          {props.entries.map(it => <Route key={`@${it.path}`} exact path={it.path} component={it.component} />)}
        </Switch>
      </div>
      {props.children}
    </div>
  );
}
