import { useCallback, useEffect, useRef, useState } from 'react';
import { useTestForRoles } from '../../../helpers/useTestForRoles';
import { useInterval } from '../../../helpers/useInterval';
import { useAPICaller, useAPISelect } from '../../../helpers/useAPI';
import { callServerMeta } from '../../../app/commonSlice';
import { callJobs } from '../jobs/jobsSlice';
import { callMuleinfo } from './muleinfoSlice';
import { callMulelogs } from './mulelogsSlice';

import './Muleinfo.module.css';
import { SpinnerText } from '../../Loading';
import { RoleNeeded } from '../../authentication/RoleNeeded';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ContextMenu } from 'primereact/contextmenu';
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { LazyLog } from 'react-lazylog';

const VERSION_NA = "N/A" as const;
const DEPLOY_FROM = 'circleci' as const;
/** result of `callMuleinfo.'selectMuleinfoApps'` */
type SelectMuleinfoResType = { apps: string[], servers: { [serverKey:string]:{[appName:string]:string} } };

/** just some text */
function AdminMuleinfo() {
  return <>
    <p>
      Cliquez sur le nom d'une application pour la déployer depuis CircleCI <br />
      Cliquez-droit sur une application pour en afficher les logs depuis Papertrail
    </p>
  </>;
}

/**
 * the large table showing on each row an app's name and its version per server (one server per column)
 * 
 * layout:
 * - (if authorized) the context menu (which allow for selection of an app to show)
 * - data table
 *   - the first column shows the apps' name
 *   - ... the rest are apps' versions on each server
 */
function TableMuleinfoApps(props: {
  /** can user deploy Mule apps? (push new 'deploy' jobs) */
  canDeployMule: boolean,
  /** can the user see the logs? (streamed from Papertrail) if true, `onSelectAppToLog` can be called with the app to show */
  canSeeLogs: boolean,
  selectServerMeta: api_v1.ServerInfo<api_v1.Server>,
  selectMuleinfoApps: SelectMuleinfoResType,
  /** called when user want to deploy an app (from table's first row) */
  putPushJobs: (key: string, app: string) => void,
  /** called when user set app to log (from contextual menu) */
  onSelectAppToLog: (key: string, app: string) => void
}) {
  const serverKeys = Object.keys(props.selectServerMeta);
  const [deploying, setDeploying] = useState("");

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const inplaceRefs = Object.fromEntries(props.selectMuleinfoApps.apps.map(app => [app, useRef<Inplace & { close:()=>void, open:()=>void }>(null)]));

  type RowType = { [key: string]: string, _name: string };

  /**
   * (when the user is authorized) enable the user to click the name of an app, which will deploy a drop down to select a server to deploy to
   * 
   * asks for confirmation when attempting to deploy (`confirmDialog`)
   * 
   * layout: (only name when not authorized)
   * - inplace
   *   - name of the app
   *   - list of server keys _except_ `DEPLOY_FROM`
   */
  const getDisplayHeader = (row: RowType) => {
    const app = row._name;
    if (props.canDeployMule) {
      const targets = serverKeys.filter(k => DEPLOY_FROM !== k).map(key => ({ label: props.selectServerMeta[key].name ?? key, value: key }));
      const deploy = (e: { value: string }) => {
        const key = e.value;
        inplaceRefs[app].current?.close();
        confirmDialog({
          message: <>Déployer <b>{app}</b> (version <b>{props.selectMuleinfoApps.servers[DEPLOY_FROM][app] ?? "inconnue"}</b>)<br/>vers <b>{props.selectServerMeta[key].name ?? key}</b> (version déjà présente : <b>{props.selectMuleinfoApps.servers[key][app] ?? "aucune"}</b>) ?</>,
          header: "Confirmation",
          icon: "pi pi-exclamation-triangle",
          accept: () => props.putPushJobs(key, app)
        });
      };
      return ( // TODO: use an OverlayPanel (see Jobs) instead of Dropdowns
        <span className="p-d-flex p-ai-center">
          <span className="p-column-title">Application</span>
          <div className="p-column-content">
            <Inplace closable disabled={!!deploying && deploying !== app} onOpen={() => setDeploying(app)} onClose={() => setDeploying("")} ref={inplaceRefs[app]}>
              <InplaceDisplay>{app}</InplaceDisplay>
              <InplaceContent>
                <Dropdown options={targets} onChange={deploy} placeholder="Déployer vers..." />
              </InplaceContent>
            </Inplace>
          </div>
        </span>
      );
    }
    return (
      <span className="p-d-flex p-ai-center">
        <span className="p-column-title">Application</span>
        <div className="p-column-content">{app}</div>
      </span>
    );
  };
  /** simply the version of the app on this server, if app is on the server (defaults to `"(no version file)"` if version is `VERSION_NA`) */
  const getDisplayVersionFor = (key: string) => (row: RowType) => {
    let ver = row[key] || "";
    if (VERSION_NA === ver) ver = "(no version file)"; // TODO: style smth up
    return (
      <span className="p-d-flex p-ai-center">
        <span className="p-column-title">{props.selectServerMeta[key]?.name ?? key}</span>
        <div className="p-column-content">{ver}</div>
      </span>
    );
  };

  // everything related to the contextual menu (used to select an app to see the logs for)
  const context = useRef<ContextMenu>();
  const [contextSelected, setContextSelected] = useState<RowType | null>();
  /** list of server to see the logs for, filters the server which does not present a version for this app */
  const contextAppOption = [{ label: "Voir logs sur...", disabled: true, style: {fontWeight:"bold"} }, ...serverKeys.map(key => ({
    label: props.selectServerMeta[key]?.name ?? key,
    command: () => contextSelected && props.onSelectAppToLog(key, contextSelected._name),
    disabled: !props.selectMuleinfoApps.servers[key][contextSelected?._name!] || DEPLOY_FROM === key,
  }))];

  /** here hoping no server is named '_name' */
  const rows: RowType[] = props.selectMuleinfoApps.apps.map(app => Object.assign({ _name: app }, ...serverKeys.map(key => ({ [key]: props.selectMuleinfoApps.servers[key][app] }))));

  return (
    <div className="datatable-responsive">
      {props.canSeeLogs && <ContextMenu model={contextAppOption} ref={context as any} onHide={() => setContextSelected(null)}/>}

      <DataTable value={rows} removableSort stripedRows sortField='_name' sortOrder={+1}
          className="p-datatable-sm p-datatable-responsive p-datatable-stripped-better p-datatable-hover-background"
          contextMenuSelection={null === contextSelected ? undefined : contextSelected}
          onContextMenuSelectionChange={e => setContextSelected(e.value)}
          onContextMenu={e => context.current?.show(e.originalEvent)}>
        <Column field='_name' header="Application" style={{ borderRightWidth: "2px" }} body={getDisplayHeader} sortable />
        {serverKeys.map(key => <Column key={key} field={key} header={props.selectServerMeta[key]?.name ?? key} body={getDisplayVersionFor(key)} />)}
      </DataTable>
    </div>
  );
}

/**
 * the dark box that streams and show the logs from Papertrail
 * 
 * @see LazyLog
 */
function AppLogs(props: {
  system: string, program: string,
  /** if no paused, will follow new logs */
  paused: boolean,
  /** delay between two fetch to Papertrail API (default 5000ms) */
  delay?: number
}) {
  const [logs, setLogs] = useState<string[]>();
  // this makes it so the logs get cleared when changing either props changes
  useEffect(() => setLogs(undefined), [props.system, props.program]);

  /** will be display until we have some logs to show */
  const defaultText = props.system && props.program
    ? "(waiting for Papertrail)"
    : `(cannot find associated Papertrail ${props.system ? "'program'" : "'system_id'"})`;

  // fetches the Papertrail API once and ask for the last 10 messages
  const [streamedLogs, streaming] = useAPISelect(callMulelogs.use('selectLogtailBegin', props.system, props.program));
  // as soon as logs are received, update state once (on every following redraw, the `streamedLogs` will contain the latest logs, but its will not accumulate)
  if (!logs && streaming) setLogs(streamedLogs?.logs);

  // following logs are retrieved by asking for the (up to) 10 more recent messages
  const getLogTail = useAPICaller(callMulelogs.use('selectLogtailContinue', props.system, props.program), (forward: string) => ({ pointParams: [forward] as [string] }));
  // set an interval to call the `selectLogtailContinue` every `delay`ms then append the result to previous logs
  const fetchUpdateLogs = () => getLogTail.current?.(streamedLogs!.forward)?.then(niw => logs && niw && setLogs([...logs, ...niw.logs]));
  useInterval(fetchUpdateLogs, props.paused || !streaming ? null : (props.delay ?? 5000));

  return <LazyLog enableSearch text={Array.isArray(logs) && 0 === logs.length ? "(no logs found yet, still waiting...)" : (logs?.join("\n") ?? defaultText)} caseInsensitive follow={!props.paused} />;
}

/**
 * a floating toggle-able panel wrapping the `AppLogs` component (or nothing if not authorized)
 * 
 * layout:
 * - tab of 3 control buttons
 *   - title (app/server being logged)
 *   - pause button (`setPaused`)
 *   - open in Papertrail button
 *   - show/hide button (`setVisible`)
 * - the logs itself
 */
function PanelMuleinfoLogs(props: {
  /** can the user see the logs? */
  canSeeLogs: boolean, title?: string | JSX.Element,
  system: string, program: string,
  paused: boolean, visible: boolean,
  setPaused?: (v: boolean) => void, setVisible?: (v: boolean) => void
}) {
  const paused = props.paused;
  const visible = props.visible;

  const title = props.title ?? <>system: <code>{props.system}</code>, program: <code>{props.program}</code></>;
  const url = props.system && props.program
    ? `https://my.papertrailapp.com/systems/${props.system}/events?q=program%3A${props.program}`
    : "https://my.papertrailapp.com/groups/4589522/events";

  const openInNewTab = useCallback(() => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  }, [url]);

  return !props.canSeeLogs ? <></> : (
    <div className="logspanel-wrapper">
      <div className="logspanel-control p-shadow-3 p-d-flex p-ai-center">
        <span className="p-mr-2">{title}</span>
        {visible && <>
          <Button
            icon={`pi ${paused ? "pi-fast-forward" : "pi-pause"}`}
            className={`p-button-rounded p-button-outlined p-button-sm ${paused ? "" : "p-button-success"} p-mr-2`}
            tooltip={paused ? "Suivre les logs" : "Mettre en pause"}
            tooltipOptions={{ position: "top" }}
            onClick={() => props.setPaused?.(!paused)} />
          <Button
            icon="pi pi-external-link"
            className="p-button-rounded p-button-outlined p-button-sm p-button-help p-mr-2"
            tooltip="Voir sur Papertrail"
            tooltipOptions={{ position: "top" }}
            onClick={() => openInNewTab()} />
        </>}
        {props.program &&
          <Button
            icon={`pi ${visible ? "pi-window-minimize" : "pi pi-window-maximize"}`}
            className="p-button-rounded p-button-outlined p-button-sm p-button-info"
            tooltip={visible ? "Fermer les logs" : "Ouvrir les logs"}
            tooltipOptions={{ position: "top" }}
            onClick={() => props.setVisible?.(!visible)} />
        }
      </div>
      {visible &&
        <div className="logspanel-panel p-shadow-12">
          <AppLogs
            system={props.system}
            program={props.program}
            paused={props.paused} />
        </div>
      }
    </div>
  );
}

/**
 * layout:
 * - `AdminMuleinfo`
 * - `TableMuleinfoApps`
 * - `PanelMuleinfoLogs`
 * 
 * * if user has read-apps or write-apps they can see the apps and logs
 * * if user has write-apps and write-jobs they can push deploy jobs
 */
export default function ScreenMuleinfo() {
  const testForRoles = useTestForRoles();

  /** toast used to notify when call is made to add a `'deploy'` job */
  const toast = useRef<Toast>(null);
  const message = (summary: string, detail: string, severity: 'success'|'info'|'warn'|'error') => toast.current?.show({ severity, summary, detail, life: 5000 });

  const [servers, ] = useAPISelect(callServerMeta.use('selectServerMeta'));
  const [muleAppVersionData, loaded] = useAPISelect(callMuleinfo.use('selectMuleinfoApps'));

  /** called back (from `TableMuleinfoApps`) when the user selects the server to deploy to (and confirms) */
  const pushDeployJob = useAPICaller(callJobs.use('putPushJobs'), (key: string, app: string) => {
    const ver = muleAppVersionData?.servers[DEPLOY_FROM][app];
    const job = { args: [app, ver ?? VERSION_NA], type: 'deploy' };
    message(`Ajout d'une tâche pour ${servers[key].name ?? key}`, `L'application '${app}' devrait bientôt être déployée (nouvelle version: '${ver ?? "inconnue"}')...`, 'info');
    return { pointParams: [key] as [string], bodyJson: job };
  });

  /** a pair of `key` (of the server) and `app` to show the logs for */
  const [showLogs, setShowLogs] = useState<{ key: string, app: string }>();
  const showLogsTitle = !showLogs
    ? "Cliquez-droit sur une application pour en afficher les logs ici"
    : <>Logs pour l'application <code>{showLogs.app}</code> (serveur : <b>{servers[showLogs.key].name}</b>)</>;

  const [logsPaused, setLogsPaused] = useState(false);
  const [logsVisible, setLogsVisible] = useState(false);

  /** called back (from `TableMuleinfoApps`) when setting app to log (using contextual menu) */
  const selectAppToLog = (key: string, app: string) => {
    setLogsPaused(false);
    setLogsVisible(true);
    setShowLogs({ key, app });
  };

  if (!loaded) return <SpinnerText />;

  return (
    <div>
      <Toast ref={toast} />

      {!testForRoles(['write-jobs', 'write-apps']) ? null : (<>
        <h3>Administration</h3>
        <AdminMuleinfo />
      </>)}

      <h1>Applications Mule sur chaque Serveur</h1>

      {!testForRoles(undefined, ['read-apps', 'write-apps'])
        ? <RoleNeeded anyOf={['read-apps', 'write-apps']} />
        : <>
            <TableMuleinfoApps
              canDeployMule={testForRoles(['write-jobs', 'write-apps'])}
              canSeeLogs={testForRoles(undefined, ['read-apps', 'write-apps'])}
              selectServerMeta={servers}
              selectMuleinfoApps={muleAppVersionData ?? { apps: [], servers: {} }}
              putPushJobs={(key, app) => pushDeployJob.current?.(key, app)?.then(res => message("La tâche à été ajoutée", `(ID de la tâche : ${res?.[0].id})`, 'success'))}
              onSelectAppToLog={selectAppToLog} />

            <PanelMuleinfoLogs
              canSeeLogs={testForRoles(undefined, ['read-apps', 'write-apps'])}
              paused={logsPaused}
              setPaused={setLogsPaused}
              visible={logsVisible}
              setVisible={setLogsVisible}
              title={showLogsTitle}
              system={!showLogs ? '' : servers[showLogs.key].host || ''}
              program={showLogs?.app || ''} />
          </>
      }
    </div>
  );
}
