export function SpinnerText(props: { className?: string, style?: React.CSSProperties, loading?: boolean, children?: React.ReactNode, text?: string }) {
  return false === props.loading ? <>{props.children}</> : (
    <div className={props.className} style={props.style}>
      <i className="pi pi-spin pi-spinner" style={{ fontSize: "2em" }}></i>
      <p>{props.text ?? "something is wrong or loading..."}</p>
    </div>
  );
}

export function SpinnerOnly(props: { className?: string, style?: React.CSSProperties, loading?: boolean, children?: React.ReactNode }) {
  return false === props.loading ? <>{props.children}</> : (
    <div className={props.className} style={props.style}>
      <i className="pi pi-spin pi-spinner" style={{ fontSize: "2em" }}></i>
    </div>
  );
}
