import { Fragment, ReactNode } from 'react';

import { Chip } from 'primereact/chip';
import { Tooltip } from 'primereact/tooltip';
import { Accordion, AccordionTab } from 'primereact/accordion';

import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import tsx from 'react-syntax-highlighter/dist/esm/languages/prism/tsx';
import tsxStyle from 'react-syntax-highlighter/dist/esm/styles/prism/a11y-dark';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';

type StringRecord = Record<string, string>;
type JsobDescription = { obj: { [name:string]: StringRecord | string }, desc: StringRecord };
const isJsob = (o: any): o is JsobDescription => o && 'number' !== typeof o && 'string' !== typeof o && 'obj' in o;

type HasChildren<T = ReactNode> = { children?: T };

// note: intended for use in a <Button /> as its onClick
// to redirect somewhere on the same page using URL fragment
const windowSetFragment = (to: string) => (e?: React.MouseEvent) => {
  e?.preventDefault();
  e?.stopPropagation();
  window.location.href = window.location.origin + window.location.pathname + "#" + to;
}

export function DocAPIRoot(props: HasChildren<any[]> & { origin: string, root: string }) {
  const lst = [...props.children ?? []];
  return (<>
    <h3>{props.root}</h3>
    <div>
      <span>{lst.shift()}</span>
      <Accordion>
        {lst.map((it: any, k) => {
          const da = 'function' === typeof it && it(props.origin, props.root);
          return !Array.isArray(da) ? da || it : <AccordionTab key={k} header={da[0]}>{da[1]}</AccordionTab>;
        })}
      </Accordion>
    </div>
  </>);
}

const BOX_BACK_COLOR = ['forestgreen', 'coral', 'cornflowerblue', 'aquamarine', ] as const;
function JsobType(props: { typename: string }) {
  const n = props.typename;
  if (n.startsWith("api_v1"))
    return <><span className="jsob-colon">{":"}</span><a href={"#"+n} style={{ textDecoration: "underline" }}>{n}</a></>;
  return <><span className="jsob-colon">{":"}</span>{props.typename}</>;
}

export function Jsob(props: HasChildren & { it?: JsobDescription, as: string, hideCaption?: boolean }) {
  const build = (key: string, obj: string | StringRecord, accKey = "", depth = 0) => {
    const color = BOX_BACK_COLOR[depth % BOX_BACK_COLOR.length];
    return (
      <div key={accKey}>
        <code className="jsob-key">{key}</code>
        <article className="jsob-val" style={{ background: color }}>
          {'string' === typeof obj ? <JsobType typename={obj} /> : (<>
            <span className="jsob-bracket-op">{"{"}</span>
            {Object.entries(obj).map(([k, v]) => build(k, v, accKey+"."+k, depth+1))}
            <span className="jsob-bracket-ed">{"}"}</span>
          </>)}
        </article>
        <summary className="jsob-txt">{props.it?.desc[accKey.substr(1) || "_"]}</summary>
      </div>
    );
  };
  return (
    <div className="jsob-root">
      <code className="jsob-key">{props.hideCaption || props.as}</code>
      <div style={{ position: "relative" }}><div style={{ position: "absolute", top: "-123px" }} id={props.as}></div></div>
      {props.it && build("", props.it.obj as StringRecord)}
    </div>
  );
}

export function HtmlAndJsob(props: HasChildren<[ReactNode, ReactNode]>) {
  return (
    <div className="p-d-flex p-flex-row p-ai-start">
      <div className="p-mr-5 p-mt-2" style={{ maxWidth: "60%" }}>{props.children?.[0]}</div>
      <div className="p-ml-auto">{props.children?.[1]}</div>
    </div>
  );
}

SyntaxHighlighter.registerLanguage('tsx', tsx);
export function Script(props: HasChildren<string>) {
  return (
    <SyntaxHighlighter language='tsx' style={tsxStyle}>
      {props.children}
    </SyntaxHighlighter>
  );
}

const METHOD_CHIP_COLOR = {
  'POST': "p-badge p-badge-success",
  'GET': "p-badge p-badge-primary",
  'PUT': "p-badge p-badge-warning",
  'PATCH': "p-badge p-badge-info",
  'DELETE': "p-badge p-badge-danger",
} as const;

export function makeDocAPIPoint(
  method: keyof typeof METHOD_CHIP_COLOR,
  point: string,
  more: {
    params: { request?: StringRecord, headers?: StringRecord, body?: JsobDescription },
    returns: JsobDescription,
    example?: { desc: string, code: string },
    secured?: boolean,
  },
  children?: ReactNode
) { return (origin: string, root: string) => {
  const path = origin + root + point;
  return [
    <div className="p-d-flex p-ai-center">
      <Chip label={method} className={METHOD_CHIP_COLOR[method]} style={{ width: "5.5rem" }} />
      <h4 className="p-my-auto">
        <code className="p-ml-3">{path.split(/(:\w+)/g).map((it, k) => {
          const da = it.substr(1);
          const id = `custom-tooltip-target-${root+point+"#"+da}`.replace(/\W+/g, "-");
          return (
            <Fragment key={k}>
              {(!it.startsWith(":") || !isNaN(+da)) ? it : (<>
                <Tooltip target={"#"+id} />
                <span id={id} data-pr-tooltip={more.params.request?.[da] as string} style={{ textDecoration: "underline" }}>{it}</span>
              </>)}
            </Fragment>
          );
        })}</code>
      </h4>
      {more.secured && <Button
        className="p-button-outlined p-ml-auto p-mr-5"
        style={{ color: "unset", padding: "revert" }}
        icon="pi pi-lock"
        tooltip="This point is secured (click to learn more)"
        tooltipOptions={{ position: "left" }}
        onClick={windowSetFragment("secured-howto")} />
      }
    </div>,
    <div>
      <div className="p-d-flex p-flex-row p-ai-start">
        <div className="p-as-start">
          <h5>Request body</h5>
          <Jsob it={more.params.body} as={path + "#body"} hideCaption />
        </div>
        <div className="p-mr-5">
          <h5>Notes</h5>
          {children}
        </div>
        <div className="p-ml-auto">
          <h5>Response body</h5>
          <Jsob it={more.returns} as={path + "#returns"} hideCaption />
        </div>
      </div>
      {more.example &&
        <span>
          <Divider align="left"><b>Example</b></Divider>
          <p>{more.example.desc}</p>
          <code>{more.example.code}</code>
        </span>
      }
    </div>
  ];
}; }

type VariableSignature = { name: string, type: (string | JsobDescription), desc?: string };
type FunctionSignature = { params: VariableSignature[], returns?: { type: string | JsobDescription, desc?: string }, example?: { desc: string, code: string } };

const TYPE_CHIP_COLOR = {
  'string': "p-badge p-badge-secondary",
  'number': "p-badge p-badge-info",
  'boolean': "p-badge p-badge-success",
  'object': "p-badge p-badge-danger",
  'void': "p-badge p-badge-warning",
  '_': "p-badge",
} as const;

export function DocFunction(props: HasChildren & { name: string } & FunctionSignature): JSX.Element;
export function DocFunction(props: HasChildren<JSX.Element[]> & { name: string } & { overloads: FunctionSignature[] }): JSX.Element;

export function DocFunction(props: HasChildren & { name: string } & Partial<FunctionSignature & { overloads: FunctionSignature[] }>) {
  const over = props.overloads ?? [{ params: props.params ?? [], returns: props.returns, example: props.example }];
  const desc = (!props.overloads ? [props.children] : props.children) as ReactNode[];
  const name = props.name;

  const getHead = (params?: VariableSignature[], returns?: { type: string | JsobDescription, desc?: string }) => {
    const pretype = returns?.type && TYPE_CHIP_COLOR[returns?.type as keyof typeof TYPE_CHIP_COLOR];
    const retype = pretype ? returns!.type as string : isJsob(returns?.type) ? "object" : (returns?.type && "object") || "void";
    const color = TYPE_CHIP_COLOR[retype.replace(/[\][?]/g, "") as keyof typeof TYPE_CHIP_COLOR] ?? TYPE_CHIP_COLOR._;
    return (
      <div className="p-d-flex p-ai-center">
        <Chip label={retype.toUpperCase()} className={color} style={{ width: "5.5rem" }} />
        <h4 className="p-my-auto">
          <code className="p-ml-3">
            {name}&nbsp;({params?.map((it, k) => {
              const cl = `custom-tooltip-target-${name+"#"+it.name}`.replace(/\W+/g, "-");
              const ty = isJsob(it.type) ? "object" : it.type;
              return (
                <Fragment key={k}>
                  {!!k && ", "}
                  <Tooltip target={"#"+cl} />
                  <span id={cl} data-pr-tooltip={(!!it.desc && !!ty ? `(${ty}) ` : ty) + (it.desc ?? "")} style={{ textDecoration: "underline" }}>{it.name}</span>
                </Fragment>
              );
            })})
          </code>
        </h4>
      </div>
    );
  }

  const getBody = (child: ReactNode, params?: VariableSignature[], returns?: { type: string | JsobDescription, desc?: string }, example?: { desc: string, code: string }) => (
    <div>
      <div className="p-d-flex p-flex-row p-ai-start">
        <div className="p-as-start">
          <h5>Call Arguments</h5>
          {params && 0 < params.length ? params.map((it, k) => (
            <Fragment key={k}>
              <code><h5>{it.name}:&nbsp;{'string' === typeof it.type && it.type}</h5></code>
              {isJsob(it.type)
                ? <Jsob it={it.type} as={name+it.name+"#params"} hideCaption />
                : <div className="jsob-root"><summary className="jsob-txt">{it.desc}</summary></div>}
            </Fragment>
          )) : <div className="jsob-root"></div>}
        </div>
        <div className="p-mr-5">
          <h5>Notes</h5>
          {child}
        </div>
        <div className="p-ml-auto">
          <h5>Returns</h5>
          {isJsob(returns?.type)
            ? <Jsob it={returns?.type} as={name + "#returns"} hideCaption />
            : <>
                <h5><code>{returns?.type}</code></h5>
                <div className="jsob-root"><summary className="jsob-txt">{returns?.desc}</summary></div>
              </>}
        </div>
      </div>
      {example &&
        <span>
          <Divider align="left"><b>Example</b></Divider>
          <p>{example.desc}</p>
          <Script>{example.code}</Script>
        </span>
      }
    </div>
  );

  return (<>
    <div>
      <Accordion>
        {over.map((it, k) => (
          <AccordionTab key={k} header={getHead(it.params, it.returns)}>
            {getBody(desc[k], it.params, it.returns, it.example)}
          </AccordionTab>
        ))}
      </Accordion>
    </div>
  </>);
}
