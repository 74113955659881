import { DocFunction, HtmlAndJsob, Jsob, Script } from '../docContents';
import { Panel } from 'primereact/panel';

export default function _() { return <>
  <p>
    (Very much très back-end so not much to see here.) <br/>
    The <code>useAPI</code> system is made to work with the <code>Redux Toolkit</code>.
  </p>
  <p>The general flow is as follow:</p>
  <ol>
    <li>create a slice using <code>createAPISlice</code> instead of <code>createSlice</code></li>
    <li>define in it a bunch of usage for the given API point</li>
    <li>export the created "extended slice"</li>
    <li><b>add the slice's selectors to the store</b> (using <code>configureStore</code>)</li>
    <li>actual calls to the API are made using either of the react hooks <code>useAPISelect</code> or <code>useAPICaller</code></li>
    <li>these hooks needs as param the result from <code>slice.use('nameHere', ..)</code></li>
  </ol>

  <h2>List of functions</h2>

  <DocFunction
    name="createAPICall"
    params={[
      { name: "name", type: "string", desc: "name of the set of calls" },
      { name: "root", type: "string", desc: "the common root for every use of this point" },
    ]}
    returns={{ type: "(uses) => APICall", desc: "an APICall creator (what even is that?!)" }}
    example={{ code:
`type UserType = { id: number, name: string, mail: string };
type ResponseType = { [id:number]: UserType };

/**
 * the API at https://some.app.s/api/v1 exposes the following:
 * - GET   /api/v1/users             -> get every users as a record of <id, { id, name, mail }>
 * - GET   /api/v1/users/:id         -> get a specific user by their id
 * - PATCH /api/v1/users/:id/setName -> change a user's name
 */
export const callUsers = createAPICall<ResponseType>('user', "https://some.app.s/api/v1/users")({
  selectUsernames: (apiResponse: ResponseType) => Object.values(apiResponse).map(u => u.name),  // same as ["/", .. => ..]
  selectUserById: ["/{0}", (apiResponse: ResponseType, id: number) => apiResponse[id]],
  updateUsernameById: ["/{0}/setName", _=>_, { method: 'PATCH', headers: {'Content-Type':"application/json"} }]
});`, desc: "I'm telling you not to use it directly..." }}>
    <p>I do not recommend using this function directly, prefer using <code>createAPISlice</code></p>
  </DocFunction>

  <DocFunction
    name="createAPISlice"
    params={[
      { name: "name", type: "string", desc: "name of the slice" },
      { name: "root", type: "string", desc: "common root for every use of this API point" },
      { name: "uses", type: "AnyValidSelector", desc: "usages, through useAPISelect or useAPICaller, for this API point" }
    ]}
    returns={{ type: "APICall & Slice", desc: "remember to register its .reducer the same as a Redux-Toolkit slice; the .use() will be use to access the API" }}
    example={{ code:
`type UserType = { id: number, name: string, mail: string };
type ResponseType = { [username:string]: UserType };

/**
 * the API at https://some.app.s/api/v1 exposes the following:
 * - GET   /api/v1/users             -> get every users as a record of <id, { id, name, mail }>
 * - GET   /api/v1/users/:id         -> get a specific user by their id
 * - PATCH /api/v1/users/:id/setName -> change a user's name
 */
export const callUsers = createAPISlice('user', "https://some.app.s/api/v1/users", {
  selectUsernames: (apiResponse: ResponseType) => Object.values(apiResponse).map(u => u.name),
  selectUserById: ["/{0}", (apiResponse: ResponseType, id: number) => apiResponse[id]],
  updateUsernameById: ["/{0}/setName", _=>_, { method: 'PATCH', headers: {'Content-Type':"application/json"} }]
}, {} as ResponseType); // empty initial state

export default callUsers.reducer;`, desc: "Creates a new APISlice, the returned object's reducer need to be added to the Redux store like any usual slice." }}>
    <p>
      This function creates a slice (or assignable to) which also has a <code>.use(name..)</code> method.
      This method will be the main way to access the usages defined when calling the <code>createAPISlice</code>.
      <br/>
      The object passed as 3<sup>rd</sup> parameter to the function defines a mapping <code>name -&gt;
      usage</code> where the <code>usage</code> is a way to use the API point and <code>name</code> will
      be used to refer to it. (See the <code>useAPISelect</code> and <code>useAPICaller</code> for more information
      on usage.)
      <br/>
      The optional 4<sup>th</sup> parameter defines the type of object that will be manipulated by this API slice.
      Hence it should be attributed an appropriate typing when given.
      <br/>
      A usage can be defined as either a selector that will take this API slice's store state (of type of the
      4<sup>th</sup> parameter) or a tuple giving a string to append at the end of the root, a selector as above
      and an optional request init maker (see <code>FetchPrepare</code> below).
      <br/>
      Note: the selector of a usage will need to account for an undefined first argument (the fetch request has
      not been completed yet but this API usage has been called for).
    </p>
  </DocFunction>

  <Panel header="APISlice usages: example and description" toggleable collapsed>
    <p>
      With the same example API as shown in <code>createAPISlice</code>'s example,
      here are some explained valid entries for the 3<sup>rd</sup> parameter "<code>uses</code>":
    </p>
    <Script>{
  `const uses = {
    /**
     * With ResponseType being a Record<user name, user info>,
     * this usage will just select the names on the list of user
     * present in the store. 'state' contains the result of the fetch.
     */
    selectUsernames: ({ state }) => state ? Object.values(state).map(u => u.name) : undefined,
    /**
     * By defining the "/{0}" path, this usage will require an additional parameter 'id: number'
     * (given at any step of a useAPI[..] flow) and this will fetch at https://some.app.s/api/v1/users/{0}
     * with {0} interpolated with the 'id' and URL-encoded.
     * Note also that giving a string ("/{0}") as path will make it store the fetch's response under
     * 'state.{0}'. This behavior can be modified by giving a full { point: "/{0}", path: "/here" }
     * (this will still fetch the same but store under 'state.here').
     */
    selectUserById: ["/{0}", ({ state }, id: number) => state?.[id]],
    /**
     * This defines a 'PATCH' usage, which would typically be use with 'useAPICaller'.
     * When needed, the third element of the tuple is used (if not undefined) to build
     * the request's init.
     * In the example case, the API does not return anything interesting to store for that request.
     * The "-" path indicates to discard the result of the fetch.
     */
    updateUsernameById: [{ point: "/{0}/setName/{1}", path: "-" }, ({ state, invalidate }, id: number, newName: string, oldName: string) => {
      if (!state) return undefined;
      // (note: maybe the API return the updated entry? then it may be stored by setting "path" to
      // for example "/tmp" so that state.tmp will be this returned updated entry)
      const updated = {
        id: state[oldName],
        name: newName,
        mail: state[oldName],
      };
      invalidate({
        ...state,             // keep everything the same,
        [oldName]: undefined, // but remove the previous entry for the updated user,
        [newName]: updated,   // and add the updated entry
      });
      // (note: what is returned there will still be accessible, so it may be used)
      return updated;
    }, { method: 'PATCH', headers: {'Content-Type':"application/json"} }],
  }`
    }</Script>
  </Panel>

  <HtmlAndJsob>
    <>
      A <code>Maker&lt;T&gt;</code> can be any of:
      <ul>
        <li>a plain object of type T</li>
        <li>a promise resolving to a T</li>
        <li>a function returning either</li>
      </ul>
      If it is a function, its arguments will be the ones given when <code>.use</code>ing the API slice
    </>
    <Jsob as="useAPI.FetchPrepare"
      it={{
        obj: {
          "method?": "'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE'",
          "headers": "Maker<Headers>",
          "body?": "Maker<Body>",
        },
        desc: {}
      }} />
  </HtmlAndJsob>

  <DocFunction
    name="useAPISelect"
    overloads={[
      {
        params: [
          { name: "useSelect", type: "APISelector", desc: "an APISlice's .use('name'..)" }
        ],
        returns: { type: "[TSelected, boolean]", desc: "a tuple with the result returned by the .use's selector function and an indicator on weather the fetch has been completed" },
        example: { code:
`// .. in some react function component
  /**
   * the example usage under 'selectUserById' requires an additional argument 'id: number'
   */
  const [user, loaded] = useAPISelect(callUsers.use('selectUserById', someId));

  if (loaded) // if loaded is true, the result of useAPISelect can be safely used
    card = <UserCard user={user} />;
// .. or something else`, desc: "Retrieve a user via the 'selectUserById' usage of the 'callUsers' slice." }
      },
      {
        params: [
          { name: "useSelect", type: "APISelector", desc: "an APISlice's .use('name'..)" },
          { name: "formatter", type: "(sel?: TSelected, ...deps) => TFormatted", desc: "" },
          { name: "...deps", type: "", desc: "the formatter's dependencies, similarly to using React's useMemo hook" }
        ],
        returns: { type: "[TSelected, TFormatted, boolean]", desc: "a tuple with the result returned by the .use's selector function, the formatted result  and the fetch status indicator" },
        example: { code:
`// .. in some react function component
  const filterFunction = (everyUsernames, onlyStartingWith: string) => everyUsernames?.filter(n => n.toLowerCase().startsWith(onlyStartingWith))
  /**
   * the example usage under 'selectUsernames' returns a list of usernames
   * the formatter's 2nd argument is used to filter through this list, when either is
   * changed, the 'filteredUsernames' will be updated accordingly
   * (remark: inline the formatter to have typing - this just example)
   */
  const [everyUsernames, filteredUsernames, loaded] = useAPISelect(callUsers.use('selectUsernames'), filterFunction, someForm.value);

  if (loaded) // if loaded is true, the result of useAPISelect can be safely used
    list = <UsernameList usernames={filteredUsernames} />;
// .. or something else`, desc: "Retrieve certain users by filtering by name." }
      }
    ]}>
    <p>
      Ask for a call to the API as defined by the usage <code>useSelect</code> (obtained by calling
      an <code>APISlice</code>'s<code>.use</code>). The tuple returned present the result of the usage's
      selector in first position as well as an boolean indicating the fetch's status (<code>true</code>
      only when the requested resources is present in the store) in last position.
      <br/>
      Note: the result of the selection will be undefined until the fetch request is completed.
      Use the tuple's last indicator (boolean) to assess its validity.
    </p>
    <p>
      Ask for a call to the API as defined by the usage <code>useSelect</code> (obtained by calling
      an <code>APISlice</code>'s<code>.use</code>). The tuple returned present the result of the usage's
      selector in first position as well as an boolean indicating the fetch's status (<code>true</code>
      only when the requested resources is present in the store) in last position.
      <br/>
      By providing an optional <code>formatter</code> argument, it will be called when the
      fetched data are received or updated, or when one of the <code>...deps</code> is updated
      (similarly to React's <code>useMemo</code>).
      <br/>
      Note: the result of the selection will be undefined until the fetch request is completed.
      Use the tuple's last indicator (boolean) to assess its validity.
      The formatting function must account for a undefined first argument.
    </p>
  </DocFunction>

  <DocFunction
    name="useAPICaller"
    params={[
      { name: "useSelect", type: "APISelector", desc: "an APISlice's .use('name'..)" },
      { name: "fetchInit", type: "(...args) => Request<ParamsMissing<...>>", desc: "a callback to initial fetches for this caller" }
    ]}
    returns={{ type: {
      obj: { "current?": "(...args) => Promise<TSelected>" },
      desc: { "current?": "the function to call to execute the actual fetch" },
    } }}
    example={{ code:
`// .. in some react function component
  const someUser = { id: someId, name: someName, ... }

  /**
   * the example usage under 'updateUsernameById' requires 3 argument 'id: number',
   * 'newName: string' and 'oldName: string', but with 'useAPICaller' some may not be known
   * as of yet (here 'newName' is not known)
   * (remark: the returned 'setName' is not itself the function to call!)
   */
  const setName = useAPICaller(callUsers.use('updateUsername', someUser.id), (newName: string) => {
    // specify the missing parameters to 'callUsers.use' as they are known at this point
    // (remark: 'as [type1, type2, ...]' may be needed as TypeScript does not infer tuples here)
    return { pointParams: [newName, oldName] as [string, string] }
  });


  const callSetName = (newName: string) => {
    // 'setName.current' can be undefined (when promises are involved)
    setName.current?.(newName, someUser.oldName)
      .then(updated => { // 'updated' here is the result from the usage's selector
        // (notify the user somehow)
        alert("Your username was successfully updated to: " + updated.name);
      });
  };

  changeNameButton = <button onClick={() => callSetName(someInput.value)}>Set New Name</button>;
// .. or something else`, desc: "Update a user's name asynchronously using a caller." }}>
    <p>
      Generate a <code>React.MutableRefObject</code> that will have for <code>.current</code> a function.
      Once this function is defined, it can be called to trigger an API call as defined by the
      usage <code>useSelect</code> (obtained by calling an <code>APISlice</code>'s<code>.use</code>).
      <br/>
      The <code>.current</code> function will take the same parameters as the passed <code>fetchInit</code>.
      If the <code>useSelect</code> relies on any <code>Promise</code>s (for example to populate
      its <code>headers</code>).
      <br/>
      This hook can take a "partial" <code>useSelect</code> in that not every parameters defined in its usage needs to
      get assigned an argument yet. When calling the <code>.current</code> function, its arguments are essentially
      forwarded to <code>fetchInit</code>. At this point, if the <code>useSelect</code> is missing some arguments,
      <code>fetchInit</code> is expected to return a <body>tuple</body> containing these.
    </p>
  </DocFunction>

  <DocFunction
    name="configureAPICalls"
    params={[
      { name: "config", type: {
        obj: {
          "verbose?": "boolean",
          "fetch?": "(input: RequestInfo, init?: RequestInit) => Promise<Response>",
          "preprocess?": "<TResponse>(response: Response) => (TResponse | Promise<TResponse>)",
        },
        desc: {
          "fetch?": "should have the same signature as the window.fetch",
          "preprocess?": "a function called with the result of fetch",
        },
      } }
    ]}>
    <p>
      The configuration is global to everything in <code>useAPI</code>.
      <br/>
      By default:
      <ul>
        <li><code>verbose</code> is <code>true</code> in development and test environnement (see <code>NODE_ENV</code>)</li>
        <li><code>fetch</code> is the <code>window.fetch</code> API</li>
        <li><code>preprocess</code> simply does "<code>res =&gt; res.json()</code>"</li>
      </ul>
    </p>
  </DocFunction>
</>; }
