import { useAuth0 } from '@auth0/auth0-react';
import { createAPISlice } from '../helpers/useAPI';

// eslint-disable-next-line react-hooks/rules-of-hooks
const headers = () => useAuth0().getAccessTokenSilently().then(token => ({
  'Authorization': `Bearer ${token}`,
  'Content-Type': "application/json",
}));

export const callServerMeta = createAPISlice('common', "/api/v1/meta", {
  selectServerMeta: ["/servers", ({ state }) => state?.servers ?? {}],

  selectMuleApps: ["/muleapps", ({ state }) => state?.muleapps ?? {}],
  patchMuleApp: [{ point: "/muleapps/update/{0}/{1}", path: "/tmp" }, ({ state, invalidate }, name: string, version: number) => {
    if (!state) return undefined;

    const updated = state.tmp[name]
    invalidate({ muleapps: { ...state.muleapps, [name]: updated }, tmp: {} });

    return updated;
  }, { method: 'PATCH', headers }],
  deleteMuleApp: [{ point: "/muleapps/delete/{0}", path: "/tmp" }, ({ state, invalidate }, name: string) => {
    if (!state) return undefined;

    const deleted = state.tmp[name];
    const muleapps = { ...state.muleapps };
    delete muleapps[name];
    invalidate({ muleapps, tmp: {} });

    return deleted;
  }, { method: 'DELETE', headers }]
}, {
  servers: {} as api_v1.ServerInfo<api_v1.Server>,
  muleapps: {} as api_v1.MuleAppInfo,
  tmp: {} as api_v1.MuleAppInfo
});

export default callServerMeta.reducer;
