import { useAuth0 } from '@auth0/auth0-react';
import { Fragment } from 'react';

function JoinRoles(props: { list: string[], sep: string, lastSep: string }) {
  const len = props.list.length;

  return <>{props.list.map((it, k) =>
    <Fragment key={k}>
      {!k || (k === len-1 ? props.lastSep : props.sep)}
      <code>{it}</code>
    </Fragment>
  )}</>;
}

export function RoleNeeded(props: { allOf: string[] }): JSX.Element;
export function RoleNeeded(props: { anyOf: string[] }): JSX.Element;

export function RoleNeeded(props: { allOf?: string[], anyOf?: string[] }) {
  const { isAuthenticated, user } = useAuth0();

  const tmp = props.allOf ?? props.anyOf ?? [];
  const isUser = isAuthenticated && !!user;

  if (1 === tmp.length) return (
    <p>
      Cet élément nécessite le rôle <code>{tmp[0]}</code> ;<br/>
      {!isUser
        ? "Vous n'être pas connecté(e)"
        : "Celui-ci doit être ajouté depuis l'interface de Auth0"}.
    </p>
  );

  if (props.allOf) return (
    <p>
      Cet élément nécessite les rôles <JoinRoles list={props.allOf} sep="," lastSep=" et " /> ;<br/>
      {!isUser
        ? "Vous n'être pas connecté(e)"
        : "Ceux-ci doivent être ajoutés depuis l'interface de Auth0"}.
    </p>
  );

  if (props.anyOf) return (
    <p>
      Cet élément nécessite un des rôles <JoinRoles list={props.anyOf} sep="," lastSep=" ou " /> ;<br/>
      {!isUser
        ? "Vous n'être pas connecté(e)"
        : "Ceux-ci peuvent être ajoutés depuis l'interface de Auth0"}.
    </p>
  );

  return <>:D</>
}
