import { useAuth0 } from '@auth0/auth0-react';
import { createAPISlice } from '../../../helpers/useAPI';

// eslint-disable-next-line react-hooks/rules-of-hooks
const headers = () => useAuth0().getAccessTokenSilently().then(token => ({
  'Authorization': `Bearer ${token}`,
  'Content-Type': "application/json",
}));

export const callProjects = createAPISlice('projects', "/api/v1/projects", {
  selectAllProjects: [
    { point: "/", path: "/list" },
    ({ state }) => state?.list,
  ],
  selectBuildSummary: [
    { point: "/summary/{0}", path: "/summaries/{0}" },
    ({ state }, reponame: string) => state?.summaries[reponame],
  ],
  postBuildTrigger: [
    { point: "/trigger/{0}/{1}", path: "/tmp" },
    ({ state, invalidate }, reponame: string, build_num?: number) => {
      if (!state) return undefined;

      invalidate({ summaries: { ...state.summaries, [reponame]: state.tmp }, tmp: {} });
      return state.tmp;
    },
    { method: 'POST', headers },
  ],
}, {
  list: [] as api_v1.ProjectUrls,
  summaries: {} as { [projectName: string]: api_v1.ProjectSummary },
  tmp: {} as api_v1.ProjectSummary,
});

export default callProjects.reducer;
