import { useReducer, useRef, useState } from 'react';
import { useAPICaller, useAPISelect } from '../../../helpers/useAPI';
import { callProjects } from './projectsSlice';
import { useTestForRoles } from '../../../helpers/useTestForRoles';

import './Projects.module.css';
import { SpinnerOnly, SpinnerText } from '../../Loading';
import { RoleNeeded } from '../../authentication/RoleNeeded';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SpeedDial } from 'primereact/speeddial';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';

const REDEPLOY_SID_PROJECT = 'salamandre-meta' as const;

const colorForStatus: Record<string, string> = {
  unknown: "secondary",
  retried: "info",
  canceled: "secondary",
  infrastructure_fail: "danger",
  timedout: "danger",
  not_run: "warning",
  running: "help",
  failed: "danger",
  queued: "help",
  scheduled: "help",
  not_running: "warning",
  no_tests: "warning",
  fixed: "success",
  success: "success",
};
const textForStatus: Record<string, string> = {
  unknown: "inconnu",
  retried: "réessayé",
  canceled: "annulé",
  infrastructure_fail: "infrastructure_fail",
  timedout: "timedout",
  not_run: "not_run",
  running: "en cours",
  failed: "échec",
  queued: "en attente",
  scheduled: "prévu",
  not_running: "not_running",
  no_tests: "no_tests",
  fixed: "fixé",
  success: "succès",
};

type ProjectInfo = { url: string, name: string };
type UserPref = { [projname: string]: 'hidden' | 'pinned' | undefined };

function ProjectSummaryEntry(props: ProjectInfo & {
  canTriggerBuild: boolean,
  onTriggerBuild: (name: string, build_num?: number) => undefined|Promise<void>
}) {
  const [summary, loaded] = useAPISelect(callProjects.use('selectBuildSummary', props.name));
  const [building, setBuilding] = useState(false);

  if (!loaded) return <SpinnerOnly style={{ height: "2rem" }} />;
  const currently = (
    "queued" === summary?.lifecycle ||
    "scheduled" === summary?.lifecycle ||
    "running" === summary?.lifecycle
  );
  if (!building && currently) setBuilding(true);
  if (building && !currently) setBuilding(false);

  const url = summary?.build_url ?? `https://app.circleci.com/pipelines/bitbucket/globule/${props.name}`;
  const build_num = summary?.build_num;
  const status = summary?.status ?? "unknown";
  const id = "project-" + props.name.replace(/\W/g, "_");

  return (
    <div className="p-d-inline-flex p-flex-row p-ai-baseline p-jc-start">
      <Button className={`p-mr-2 p-button-rounded p-button-small p-button-${colorForStatus[status]}`}
        style={{ minWidth: "5.5rem", height: "2rem" }}
        label={textForStatus[status]}
        onClick={() => {
          const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
          if (newWindow) newWindow.opener = null;
        }} />

      {props.canTriggerBuild &&
        <Button className="p-mr-3 p-button-sm" style={{ minWidth: "6.1rem", height: "2rem" }}
          label={"Rebuild" + (building ? " en cours" : "")}
          icon={"pi " + (building ? "pi-spin pi-spinner" : "pi-play")}
          onClick={() => {
            if (building) {
              const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
              if (newWindow) newWindow.opener = null;
            } else {
              confirmDialog({
                message: <>Re-build <b>{props.name}</b> ({build_num ? "n°" + (build_num ?? "??") : "latest"}) ?</>,
                header: "Confirmation",
                icon: "pi pi-exclamation-triangle",
                accept: () => {
                  setBuilding(true);
                  props.onTriggerBuild(props.name, build_num)?.then(() => setBuilding(false));
                },
              });
            }
          }} disabled={undefined === build_num} />
      }

      <span className="p-d-inline-flex" style={{ minWidth: "4rem" }}>
        n°{build_num ?? "??"}
      </span>

      {!!summary?.body &&
        <Tooltip target={"#tooltip-summary-body-" + id}>
          <code>{summary?.body}</code>
        </Tooltip>
      }
      <span id={"tooltip-summary-body-" + id}>
        {summary?.subject}
      </span>
    </div>
  );
}

function ProjectTable(props: {
  items: ProjectInfo[],
  userprefType: 'hidden' | 'pinned' | undefined,
  onUpdateUserpref: (updated: UserPref) => void,
  canTriggerBuild: boolean,
  onTriggerBuild: (name: string, build_num?: number) => undefined|Promise<void>,
  className?: string,
  style?: React.CSSProperties,
  id?: string,
}) {
  const pref = props.userprefType;
  const items = props.items;
  const [, requestUpdate] = useReducer(c => c + 1, 0);

  const actions = (it: ProjectInfo) => {
    const model = [
      {
        label: 'pinned' === pref ? "détacher" : "épingler",
        icon: 'pinned' === pref ? 'pi pi-star-o' : 'pi pi-star',
        command: () => props.onUpdateUserpref({ [it.name]: 'pinned' === pref ? undefined : 'pinned' }),
      },
      {
        label: 'hidden' === pref ? "montrer" : "cacher",
        icon: 'hidden' === pref ? 'pi pi-eye' : 'pi pi-eye-slash',
        command: () => props.onUpdateUserpref({ [it.name]: 'hidden' === pref ? undefined : 'hidden' }),
      },
    ];
    if ('hidden' !== pref)
      model.push({
        label: "réactualiser", icon: 'pi pi-refresh',
        command: () => {
          callProjects.invalidate('selectBuildSummary', it.name);
          requestUpdate();
        },
      });

    return (
      <div className="speeddial-tooltip-wrapper">
        <SpeedDial
          direction="right"
          style={{ transform: "translate(-5%, -50%)" }}
          buttonStyle={{ transform: "scale(.5)" }}
          buttonClassName="p-button-outlined"
          showIcon="pi pi-tags"
          model={model} />
      </div>
    );
  };

  const status = (it: ProjectInfo) => <ProjectSummaryEntry url={it.url} name={it.name} onTriggerBuild={props.onTriggerBuild} canTriggerBuild={props.canTriggerBuild} />;

  const links = (it: ProjectInfo) => {
    return (<>
      <Button tooltip="CircleCI"
        tooltipOptions={{ position: "top" }}
        className="p-button-rounded p-button-sm p-mr-2"
        icon="pi pi-circle-off"
        style={{ width: "2rem", height: "2rem" }}
        onClick={() => {
          const newWindow = window.open(`https://app.circleci.com/pipelines/bitbucket/globule/${it.name}`, '_blank', 'noopener,noreferrer');
          if (newWindow) newWindow.opener = null;
        }} />
      <Button tooltip="Bitbucket"
        tooltipOptions={{ position: "top" }}
        className="p-button-rounded p-button-sm"
        icon="pi pi-home"
        style={{ width: "2rem", height: "2rem" }}
        onClick={() => {
          const newWindow = window.open(it.url, '_blank', 'noopener,noreferrer');
          if (newWindow) newWindow.opener = null;
        }} />
    </>);
  };

  const header = 'hidden' !== pref && (
    <div className="table-header" style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    }}>
      {'pinned' === pref
        ? "Projets épinglés"
        : "Liste des projets"
      }
      <Button icon="pi pi-refresh"
        onClick={() => {
          items.forEach(it => callProjects.invalidate('selectBuildSummary', it.name));
          requestUpdate(); // re-renders so each <ProjectSummaryEntry /> get a chance to update store
        }} />
    </div>
  );

  // list tagged 'hidden' is handled a bit differently (not even fully loaded)
  const r = 'hidden' === pref
    ? <Accordion>
        <AccordionTab header={`Projets cachés (${items.length})`}>
          <DataTable value={items} className="p-datatable-layout-auto" sortField="name" sortOrder={1}>
            <Column header=""       body={actions} headerStyle={{ width: "5%" }} />
            <Column header="Projet" field='name'   headerStyle={{ width: "80%" }} sortable />
            <Column header="Liens"  body={links}   headerStyle={{ width: "15%" }} />
          </DataTable>
        </AccordionTab>
      </Accordion>
    : <DataTable value={items} header={header} className="p-datatable-layout-auto" sortField="name" sortOrder={1}>
        <Column header=""       body={actions} headerStyle={{ width: "5%" }} />
        <Column header="Projet" field='name'   headerStyle={{ width: "20%" }} sortable />
        <Column header="Status" body={status}  headerStyle={{ width: "60%" }} />
        <Column header="Liens"  body={links}   headerStyle={{ width: "15%" }} />
      </DataTable>
    ;
  return <div className={props.className} style={props.style} id={props.id}>{r}</div>;
}

function EveryProjectTables(props: {
  items: ProjectInfo[],
  userpref: UserPref,
  onUpdateUserpref: (updated: UserPref) => void,
  canTriggerBuild: boolean,
  onTriggerBuild: (name: string, build_num?: number) => undefined|Promise<void>
}) {
  const pinned: ProjectInfo[] = [];
  const hidden: ProjectInfo[] = [];
  const rest = props.items.filter(({ url, name }) => {
    if ('hidden' === props.userpref[name]) {
      hidden.push({ url, name });
      return false;
    }
    if ('pinned' === props.userpref[name]) {
      pinned.push({ url, name });
      return false;
    }
    return true;
  });

  return (<>
    <Tooltip target=".p-speeddial-action" position="top" />

    {!!pinned.length &&
      <ProjectTable items={pinned} userprefType="pinned"
        onUpdateUserpref={props.onUpdateUserpref}
        canTriggerBuild={props.canTriggerBuild}
        onTriggerBuild={props.onTriggerBuild}
        className="p-mb-5" />
    }

    <ProjectTable items={rest} userprefType={undefined}
      onUpdateUserpref={props.onUpdateUserpref}
      canTriggerBuild={props.canTriggerBuild}
      onTriggerBuild={props.onTriggerBuild} />

    {!!hidden.length &&
      <ProjectTable items={hidden} userprefType="hidden"
        onUpdateUserpref={props.onUpdateUserpref}
        canTriggerBuild={props.canTriggerBuild}
        onTriggerBuild={props.onTriggerBuild}
        className="p-mt-5" />
    }
  </>);
}

export default function ScreenProjects() {
  const [userprefStore, userprefDispatch] = useReducer(
    (state: UserPref, action: UserPref) => {
      const niw = Object.assign({}, state, action);
      localStorage.setItem('stethoscope.preferences.projects', JSON.stringify(niw));
      return niw;
    },
    JSON.parse(localStorage.getItem('stethoscope.preferences.projects') ?? "{}")
  );

  const [projects, loaded] = useAPISelect(callProjects.use('selectAllProjects'));
  const [filter, setFilter] = useState("");

  const triggerBuild = useAPICaller(callProjects.use('postBuildTrigger'), (name: string, build_num?: number) => {
    message(`Re-build de ${name}`, `(relance du build n°${build_num})`, 'info');
    return { pointParams: [name, build_num] as [string, number|undefined] };
  });

  const redeploySID = () => {
    confirmDialog({
      message: <>Redéployer SID ?</>,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => triggerBuild.current?.(REDEPLOY_SID_PROJECT)
          ?.then(() => message("Le server à répondu sans problème", "", 'success'))
          .catch(err => message("Erreur quelque part", `"${err.message}"`, 'error')),
    });
  };

  const testForRoles = useTestForRoles();

  /** toast used to notify when call is made to rebuild or redeploy */
  const toast = useRef<Toast>(null);
  const message = (summary: string, detail: string, severity: 'success'|'info'|'warn'|'error') => toast.current?.show({ severity, summary, detail, life: 5000 });

  if (!loaded) return <SpinnerText />;

  const infos = projects?.flatMap(url => {
    const name = url.slice(url.lastIndexOf('/')+1);
    if (!filter || -1 < name.indexOf(filter))
      return [{ url, name }];
    return [];
  }) ?? [];

  const readRole = testForRoles(undefined, ['read-projs', 'write-projs']);
  const writeRole = testForRoles(['write-projs']);

  return (
    <div>
      <Toast ref={toast} />

      <h1 className="p-d-flex p-ai-center">
        <span style={{ flexGrow: 1 }}>Liste des projets</span>
        {writeRole &&
          <Button className="p-button-danger"
            onClick={redeploySID}
            label="Redéployer SID" />
        }
      </h1>

      {!readRole
        ? <RoleNeeded anyOf={['read-projs', 'write-projs']} />
        : (<>
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText className="p-py-2"
                value={filter} placeholder="Rechercher..."
                onChange={e => setFilter(e.target.value)} />
            </span>
            <span className="p-ml-3">
              ({infos.length} {!filter ? "au total" : "résultats"})
            </span>

            <EveryProjectTables items={infos}
              onUpdateUserpref={userprefDispatch}
              canTriggerBuild={writeRole}
              onTriggerBuild={(name, build_num) =>
                triggerBuild.current?.(name, build_num)
                  ?.then(() => message("Le server à répondu sans problème", "", 'success'))
                  .catch(err => message("Erreur quelque part", `"${err.message}"`, 'error'))}
              userpref={userprefStore} />
          </>)
      }
    </div>
  );
}
