import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'primereact/button';

export default function LoginButton() {
  const { loginWithRedirect } = useAuth0();
  return (
    <Button
      className="p-button-info p-m-3"
      label="Connexion"
      onClick={() => loginWithRedirect()} />
  );
}
