import { DocFunction } from '../docContents';

export default function _() { return <>
  <h4>Add a new job type</h4>
  <p>The job types are defined in the <code>helpers/database.ts</code> file of the server's sources. The enumeration <code>JobTypes</code> would need to be updated with the new type(s).</p>

  <h4>Add an Auth0 role</h4>
  <p>Auth0 roles are managed from the <a href="https://manage.auth0.com/dashboard/">Auth0 dashboard</a> for the Stethoscope project, under <code>"User Management" &gt; "Roles"</code>, and can be attributed directly from a user's page (<code>"User Management" &gt; "Users" &gt; "Roles"</code>).</p>
  <p>To test for a user's role, the following React hook can be used.</p>
  <DocFunction
    name="useTestForRoles"
    overloads={[
      {
        params: [
          { name: "allOf?", type: "string[]" },
          { name: "anyOf?", type: "string[]" },
        ],
        returns: { type: "boolean" },
      },
      {
        params: [],
        returns: { type: "(allOf?: string[], anyOf?: string[]) => boolean" },
      },
    ]}>
    <>
      <p>If <code>allOf</code> is specified, the result is only true if the user has every roles in this list.</p>
      <p>If <code>anyOf</code> is specified (or both), the result is true if the user has at least one roles from this list.</p>
    </>
    <p>
      Returns a function that will act the same as <code>useTestForRoles</code>. This allows to pass the restriction on React hooks (eg. conditional call).
    </p>
  </DocFunction>
</>; }
