import { DocAPIRoot, makeDocAPIPoint, Jsob, HtmlAndJsob } from '../docContents';
import { Tag } from 'primereact/tag';

const _ROOT = window.location.origin.split("//")[1] + "/api/v1";

export default function _() { return <>
  <span id="secured-howto" />

  <p>
    The API lives at <code>{_ROOT}/api/v1</code>. <br/>
    Every call with a JSON body requires the appropriate <code>'Content-Type': "application/json"</code> header.
  </p>

  <h2>Security (M2M access)</h2>
  <p>
    To access a "secured" API point (with a lock on it), the request must include an{" "}
    <code>'Authorization'</code> header.<br/>
    The authorization token (and token type) can be exchanged for with a client id and
    a client secret at the <code>/auth/token/:key</code> API point.
  </p>
  <p>
    Note: the received token is valid for the next 24h (or any other duration specified
    in the response). It would be preferable to save and reuse a same token (the limit
    imposed by Auth0 is about 1000 calls/month but still...)
  </p>
  <p>
    Note on the client ID &amp; secret: with Auth0, those variables are accessible from the
    management panel, under Applications &gt; Application &gt; "Stéthoscope - M2M", see the
    "Settings" tab. (Reminder not to commit ID nor secret.)
  </p>

  <h2>List of exposed points</h2>

  <DocAPIRoot origin={_ROOT} root="/meta">
    <div>
      <p>Metadata</p>
    </div>
    {makeDocAPIPoint("GET", "/servers", {
      params: {},
      returns: {
        obj: {
          "[serverKey: string]": {
            "name?": "string",
            "url?": "string",
            "host?": "string"
          }
        },
        desc: { "[serverKey: string].host?": "Name of the host of this server, should be the same as the Papertrail 'System' that show logs of said server" }
      }
    })}
    {makeDocAPIPoint("GET", "/muleapps", {
      params: {},
      returns: {
        obj: {
          "[appName: string]": {
            "latest": "string",
            "url": "string",
            "region": "string"
          }
        },
        desc: { "[appName: string].region": "The region for AWS S3", "[appName: string].url": "URL of the package (.zip), with '###'s where the version would go" }
      }
    })}
    {makeDocAPIPoint("GET", "/muleapps/latest/:name", {
      params: {
        request: { "name": "Name of the application to get the latest of" }
      },
      returns: {
        obj: {
          "version": "string",
          "url": "string",
          "region": "string"
        },
        desc: { "[appName: string].region": "The region for AWS S3", "[appName: string].url": "URL of the package (.zip), with '###'s where the version would go" }
      }
    }, <p>(unused point..?) Same as <code>/muleapps</code> but selecting a specific entry (returns only one app &mdash; note the difference in <code>version</code> instead of <code>latest</code>.)</p>)}
    {makeDocAPIPoint("PATCH", "/muleapps/update/:name/:version?", {
      params: {
        request: {
          "name": "Name of the application to update",
          "version": "Version to set as latest for this app"
        },
        body: {
          obj: {
            "url?": "string",
            "region?": "string"
          },
          desc: {
            "_": "Note: the body for this request is optional",
            "url": "New URL to set for this app",
            "region": "New region (AWS S3) to set for this app"
          }
        }
      },
      returns: {
        obj: {
          "[appName: string]": {
            "version": "string",
            "url": "string",
            "region": "string"
          },
          "wasInserted": "boolean"
        },
        desc: { "[appName: string].region": "The region for AWS S3", "[appName: string].url": "URL of the package (.zip), with '###'s where the version would go", "wasInserted": "Whether the app was updated or inserted (as new entry in the database)" }
      },
      secured: true
    }, <p>If the app did not already exist, it is added to the database.</p>)}
    {makeDocAPIPoint("DELETE", "/muleapps/delete/:name", {
      params: {
        request: { "name": "Name of the application to delete" }
      },
      returns: {
        obj: {
          "[appName: string]": {
            "version": "string",
            "url": "string",
            "region": "string"
          }
        },
        desc: { "[appName: string].region": "The region for AWS S3", "[appName: string].url": "URL of the package (.zip), with '###'s where the version would go" }
      }
    }, <p>The returned info represent the deleted app.</p>)}
  </DocAPIRoot>

  <DocAPIRoot origin={_ROOT} root="/auth">
    <div>
      <p>Machine to machine authentication</p>
    </div>
    {makeDocAPIPoint("GET", "/token/:key", {
      params: {
        request: { "key": "A key identifying what is trying to get an authentication (for example a server's key)" }
      },
      returns: {
        obj: { "url": "string", "audience": "string" },
        desc: { "url": "The url to POST at to get a client authentication token" }
      }
    }, <p>Remark: this point does not return the authentication token itself but where to get it.</p>)}
    {makeDocAPIPoint("POST", "/token/:key", {
      params: {
        request: {
          "key": "A key identifying what is trying to get an authentication (for example a server's key)",
          "client_id": "M2M client id (should be obtained from Auth0)",
          "client_secret": "M2M client secret (should be obtained from Auth0)",
        },
        body: {
          obj: {
            "client_id": "string",
            "client_secret": "string",
          },
          desc: {}
        }
      },
      returns: {
        obj: {
          "expires_in": "number",
          "token_type": "string",
          "access_token": "string",
        },
        desc: {
          "expires_in": "Duration in seconds"
        }
      }
    }, <p>Usage of the returned token: add the header <code>'Authorization': "$&#123;token_type&#125; $&#123;access_token&#125;"</code> to access a secured point.</p>)}
  </DocAPIRoot>

  <DocAPIRoot origin={_ROOT} root="/versions">
    <HtmlAndJsob>
      <p>About the <code>version.txt</code> files on each server. The type manipulated here is <code>api_v1.Version</code>.</p>
      <Jsob as="api_v1.Version"
        it={{
          obj: { "timestamp": "Date", "revision": "string" },
          desc: {}
        }} />
    </HtmlAndJsob>
    {makeDocAPIPoint("GET", "/", {
      params: {},
      returns: {
        obj: { "[serverKey: string]": "api_v1.Version" },
        desc: {}
      }
    })}
    {makeDocAPIPoint("GET", "/:key", {
      params: {
        request: { "key": "Key of the server to get the revision for" }
      },
      returns: {
        obj: { "[serverKey: string]": "api_v1.Version" },
        desc: { "serverKey": "Same as parameter 'key'" }
      }
    })}
    {makeDocAPIPoint("GET", "/fromtxts/:key", {
      params: {
        request: { "key": "Key of the server to get the revision for" }
      },
      returns: {
        obj: { "[serverKey: string]": "api_v1.Version" },
        desc: { "serverKey": "Same as parameter 'key'" }
      }
    }, <p><Tag className="p-mr-2" icon="pi pi-exclamation-triangle" severity="warning" value="deprecated" />Same as <code>/versions/:key</code> but forces the API to use the <code>version.txt</code> file and ignore any cached data.</p>)}
  </DocAPIRoot>

  <DocAPIRoot origin={_ROOT} root="/muleinfo">
    <HtmlAndJsob>
      <p>About the applications on Mule. Type used is <code>api_v1.Muleinfo</code>.</p>
      <Jsob as="api_v1.Muleinfo"
        it={{
          obj: { "apps?": { "[name: string]": "string" } },
          desc: { "apps.[name]": "Version (number only) of the Mule app (eg. not '1.0.2-42' but just '42')" }
        }} />
    </HtmlAndJsob>
    {makeDocAPIPoint("GET", "/", {
      params: {},
      returns: {
        obj: { "[serverKey: string]": "api_v1.Muleinfo" },
        desc: {}
      }
    })}
    {makeDocAPIPoint("GET", "/:key", {
      params: {
        request: { "key": "Key of the server to get the applications for" }
      },
      returns: {
        obj: { "[serverKey: string]": "api_v1.Muleinfo" },
        desc: { "serverKey": "Same as parameter 'key'" }
      }
    })}
    {makeDocAPIPoint("PATCH", "/:key", {
      params: {
        request: { "key": "Key of the server to update the applications for" },
        body: {
          obj: { "apps": { "[name: string]": "string"} },
          desc: { "apps.[name]": "New version for the application (can be of the form '1.0.2-42', only '42' will be kept)" }
        }
      },
      returns: {
        obj: { "[serverKey: string]": "api_v1.Muleinfo" },
        desc: { "serverKey": "Same as parameter 'key'" }
      },
      secured: true
    })}
    {makeDocAPIPoint("GET", "/fromlogs/:key", {
      params: {
        request: { "key": "Key of the server to get the applications for" }
      },
      returns: {
        obj: { "[serverKey: string]": "api_v1.Muleinfo" },
        desc: { "serverKey": "Same as parameter 'key'" }
      },
    }, <p><Tag className="p-mr-2" icon="pi pi-exclamation-triangle" severity="warning" value="deprecated" />Same as <code>/muleinfo/:key</code> but forces the API to use Papertrail logs and ignore any cached data.</p>)}
  </DocAPIRoot>

  <DocAPIRoot origin={_ROOT} root="/jobs">
    <HtmlAndJsob>
      <p>To push new jobs, list existing ones and change their status. Jobs are presented in the following <code>api_v1.Jobs</code> type, which is <b>an array</b> of objects.</p>
      <Jsob as="api_v1.Jobs"
        it={{
          obj: {
            "id": "string",
            "type": "'none' | 'deploy' | ...",
            "args?": "string[]",
            "status": "'Unknown' | 'Given' | 'Waiting' | 'Done' | 'Aborted'",
            "result?": "string",
            "given_on": "Date",
            "status_on?": "Date"
          },
          desc: {}
        }} />
    </HtmlAndJsob>
    {makeDocAPIPoint("GET", "/all", {
      params: {},
      returns: {
        obj: { "[serverKey: string]": "api_v1.Jobs" },
        desc: {}
      }
    }, <>Lists every jobs for every servers.</>)}
    {makeDocAPIPoint("GET", "/all/:key", {
      params: {
        request: { "key": "Key of the server to get jobs for" }
      },
      returns: {
        obj: { "[serverKey: string]": "api_v1.Jobs" },
        desc: { "serverKey": "Same as parameter 'key" }
      }
    }, <>Lists every jobs for the requested server.</>)}
    {makeDocAPIPoint("GET", "/todo/:key", {
      params: {
        request: { "key": "Key of the server to get jobs for" }
      },
      returns: {
        obj: { "[serverKey: string]": "api_v1.Jobs" },
        desc: { "serverKey": "Same as parameter 'key" }
      }
    }, <>Filters jobs with the status "Given" for the requested server.</>)}
    {makeDocAPIPoint("PUT", "/push/:key", {
      params: {
        request: { "key": "Key of the server to push the new job for" },
        body: {
          obj: { "type": "'none' | 'deploy' | ...", "args?": "string[]" },
          desc: { "_": "May also be a list, to add multiple jobs at once" }
        }
      },
      returns: {
        obj: { "[serverKey: string]": "api_v1.Jobs" },
        desc: { "_": "Only returns the added job(s)", "serverKey": "Same as parameter 'key" }
      },
      secured: true
    }, <>Pushes new jobs (with the status "Given") for the requested server.</>)}
    {makeDocAPIPoint("PUT", "/done/:key/:id", {
      params: {
        request: { "key": "Key of the server to update the job for", "id": "Id of the job to update" },
        body: {
          obj: { "result?": "string" },
          desc: { "result": "A finished job can state an optional result" }
        }
      },
      returns: {
        obj: { "[serverKey: string]": "api_v1.Jobs" },
        desc: { "_": "Only returns the updated job", "serverKey": "Same as parameter 'key" }
      },
      secured: true
    }, <>Set an existing job's status to "Done" for the requested server.</>)}
    {makeDocAPIPoint("PUT", "/abort/:key/:id", {
      params: {
        request: { "key": "Key of the server to update the job for", "id": "Id of the job to update" },
        body: {
          obj: { "result?": "string" },
          desc: { "result": "A canceled job can state an optional result" }
        }
      },
      returns: {
        obj: { "[serverKey: string]": "api_v1.Jobs" },
        desc: { "_": "Only returns the updated job", "serverKey": "Same as parameter 'key" }
      },
      secured: true
    }, <>Set an existing job's status to "Aborted" for the requested server.</>)}
  </DocAPIRoot>

  <DocAPIRoot origin={_ROOT} root="/projects">
    <HtmlAndJsob>
      <>
        <p>This offers access to the <a href="https://circleci.com/docs/api/#circleci-v1-api-overview">CircleCI API</a>, see its documentation for more details.</p>
        <p>For the actual complete type, see <a href="https://circleci.com/docs/api/#recent-builds-for-a-single-project">the official documentation</a>.</p>
        <div className="p-d-flex p-jc-around">
          <div>
            Values for <code>"status"</code>:
            <ul>
              <li>unknown</li>
              <li>retried</li>
              <li>canceled</li>
              <li>infrastructure_fail</li>
              <li>timedout</li>
              <li>not_run</li>
              <li>running</li>
              <li>failed</li>
              <li>queued</li>
              <li>scheduled</li>
              <li>not_running</li>
              <li>no_tests</li>
              <li>fixed</li>
              <li>success</li>
            </ul>
          </div>
          <div>
            Values for <code>"lifecycle"</code>:
            <ul>
              <li>queued</li>
              <li>scheduled</li>
              <li>not_run</li>
              <li>not_running</li>
              <li>running</li>
              <li>finished</li>
            </ul>
          </div>
        </div>
      </>
      <Jsob as="api_v1.ProjectSummary"
        it={{
          obj: {
            "status": "string",
            "build_url": "number",
            "lifecycle": "string",
            "subject": "string",
            "body": "string",
          },
          desc: {
            "subject": "Subject of the last commit message (~ 'first line')",
            "body": "Body of the last commit message",
          }
        }} />
    </HtmlAndJsob>
    {makeDocAPIPoint("GET", "/", {
      params: {
        request: { "reponame": "Name of the repository" }
      },
      returns: {
        obj: { " ": "string[]" },
        desc: { "_": "A list of project URL (https://bitbucket.org/globule/{reponame})"}
      }
    })}
    {makeDocAPIPoint("GET", "/summary/:reponame", {
      params: {
        request: { "reponame": "Name of the repository" }
      },
      returns: {
        obj: { " ": "api_v1.ProjectSummary" },
        desc: {}
      }
    }, <>(See <a href="https://circleci.com/docs/api/#recent-builds-for-a-single-project">here</a>) Returns a summary of the latest CircleCI build for the project</>)}
    {makeDocAPIPoint("POST", "/follow/:reponame", { 
      params: {
        request: { "reponame": "Name of the repository" }
      },
      returns: {
        obj: {
          "followed": "boolean",
          "first_build": "Build"
        },
        desc: {}
      },
      secured: true
    }, <>(See <a href="https://circleci.com/docs/api/#follow-a-new-project-on-circleci">here</a>.)</>)}
    {makeDocAPIPoint("POST", "/trigger/:reponame/:build_num?", { 
      params: {
        request: {
          "reponame": "Name of the repository",
          "build_num": "An optional build number (defaults to latest)"
        }
      },
      returns: {
        obj: { " ": "api_v1.ProjectSummary" },
        desc: {}
      },
      secured: true
    }, <>(See <a href="https://circleci.com/docs/api/#retry-a-build">here</a>.)</>)}
  </DocAPIRoot>
</>; }
